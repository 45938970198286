import React from 'react';
import Downshift from 'downshift';
import {API} from "../../../network/api";
import Grid from "@material-ui/core/Grid";
import {ListItem, ListItemText, TextField} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import FaceIcon from '@material-ui/icons/Face';
import {debounce} from 'throttle-debounce';
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import PaddingPaper from "../../common/PaddingPaper";

export default class CopSearchForm extends React.Component {

  state = {
    inputValue: '',
    isSearching: false,
    searches: [],
    cops: [],
  };

  componentDidMount() {
    this.debounceSearch = debounce(500, this.search);
  }

  search = () => {
    const {isSearching} = this.state;
    const {extendedSearchResult} = this.props || false;
    if (isSearching) return;
    const [last] = this.state.searches.slice(-1);
    if (last) {
      this.setState(() => ({isSearching: true, searches: []}));
      API.getCops(last, extendedSearchResult).then(response => {
        const {cops} = response.data;
        this.setState(() => ({cops, isSearching: false}));
      }).catch(error => {
        this.setState(() => ({isSearching: false}));
        console.error("Could not search cops", error.response);
      });
    }
  };

  onChange = (event) => {
    const {value} = event.target;
    const {searches} = this.state;
    searches.push(value);
    this.setState(() => ({inputValue: value, searches}), () => this.debounceSearch());
  };

  onRemoveCop = () => {
    this.props.onSelectCop(null);
  };

  onSelectCop = (selectedCop) => {
    this.props.onSelectCop(selectedCop);
  };

  _renderSelected = (cop) => {
    return <Chip tabIndex={-1}
                 label={`${cop.rank} ${cop.name} - ${cop.registration}`}
                 color="primary"
                 icon={<FaceIcon/>}
                 onDelete={this.onRemoveCop}/>;
  };

  _renderInput = (inputValue, cops) => {
    return (
      <PaddingPaper>
        <Downshift>
          {({getInputProps, getMenuProps}) => (
            <div>
              <TextField type="text" name="userSearch"
                         fullWidth {...getInputProps({label: "Busque por um miltar aqui"})} value={inputValue}
                         onChange={this.onChange}/>
              <div {...getMenuProps()}>
                {cops.length ? (
                  <Paper square>
                    {cops.map(cop => (
                      <ListItem key={cop.id} button onClick={() => this.onSelectCop(cop)}>
                        <ListItemText primary={`${cop.rank} ${cop.fullName}`}
                                      secondary={`Matrícula: ${cop.registration}`}/>
                      </ListItem>
                    ))}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </PaddingPaper>
    );
  };

  render() {
    const {inputValue, cops} = this.state;
    const {selectedCop} = this.props;
    const result = selectedCop
      ? this._renderSelected(selectedCop)
      : this._renderInput(inputValue, cops);
    return (
      <Grid container>
        <Grid item xs={12}>
          {result}
        </Grid>
      </Grid>
    );
  }

}

CopSearchForm.propTypes = {
  extendedSearchResult: PropTypes.bool,
  selectedCop: PropTypes.object,
  onSelectCop: PropTypes.func.isRequired
};