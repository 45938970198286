import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getPermissionsForAppOnly} from "../../../network/permissions";
import {useGlobalNotifications} from "../../common/GlobalNotifications";
import {List, ListItem, ListItemText} from "@material-ui/core";
import {useHistory} from "react-router";
import Routes from "../../../constants/Routes";

export default function UsersWithPermissionInAppSelectPermissionPage() {

  const {appId} = useParams()
  const history = useHistory()
  const {showFailure} = useGlobalNotifications()
  const [{name, roles}, setState] = useState({
    name: null,
    roles: []
  })

  useEffect(() => {
    getPermissionsForAppOnly(appId).then(res => {
      const {appRoles, appName} = res.data;
      setState({name: appName, roles: appRoles})
    }).catch(err => {
      const {error} = err.response.data;
      showFailure(error || 'Não foi possível carregar permissões para o app')
    })
  }, [appId, showFailure])

  const onRoleSelection = (role) => {
    const url = Routes.USERS_WITH_PERMISSIONS_IN_APP_LIST_USERS_PATH(appId, role.id)
    history.push({
      pathname: url,
      search: encodeURI(`name=${role.name}`)
    })
  }

  return (
    <>
      {name && (
        <>
          <h2>Selecione uma Permissão</h2>
          <h5>App: {name}</h5>
        </>
      )}
      <List component="nav">
      </List>
      {roles.map(r => (
        <ListItem button divider onClick={() => onRoleSelection(r)} key={r.name}>
          <ListItemText primary={r.name} secondary={r.description}/>
        </ListItem>
      ))}
    </>
  )

}