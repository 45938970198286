
/** Check browser name and version
 * See: https://www.digitalminds.io/blog/detecting-outdated-browsers-in-vanilla-javascript
 * */
function browserInfo() {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) {
      return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return { name: M[0], version: M[1] };
};

/** We only support modern versions of Chrome, Firefox and Safari */
export default function isBrowserSupported () {
  const {name, version} = browserInfo();
  switch (name.toLowerCase()) {
    case 'chrome':
      return version > 80;
    case 'firefox':
      return version > 70;
    case 'safari':
      return version > 10;
    default:
      return false;
  }
}