import {useLocation} from "react-router-dom";

/** Use the URLSearchParams API to get query params
 * e.g:
 * const query = useQuery()
 * const param = query.get('param_name')
 * */
export default function useQuery() {
  const location = useLocation()
  return new URLSearchParams(location.search)
}