import React from 'react';

export default function UnsupportedBrowser() {

  const links = [
    {
      name: 'Google Chrome',
      url: 'https://www.google.com/intl/pt-BR/chrome/'
    },
    {
      name: 'Firefox',
      url: 'https://www.mozilla.org/pt-BR/'
    },
    {
      name: 'Safari (Apenas iOS e Mac)',
      url: 'https://www.apple.com/safari/'
    },
  ]

  return (
    <div style={{textAlign: 'center', fontFamily: '"Source Sans Pro", "Arial", sans-serif'}}>
      <h2>Seu navegador não é suportado.</h2>
      <h3>Por favor, utilize a versão mais recente de um dos navegadores abaixo:</h3>
      <ul style={{listStyleType: 'none', padding: 0}}>
        {links.map(l =>
          <li key={l.name} style={{margin: 8}}>
            <a href={l.url}>{l.name}</a>
          </li>
        )}
      </ul>
    </div>
  );

}