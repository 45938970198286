import {useHistory} from "react-router-dom";
import {List, ListItem, ListItemText} from "@material-ui/core";
import Routes from "../../../constants/Routes";
import React from "react";

export default function BirthdayOptionsPage() {
  const history = useHistory();
  return(
    <>
      <h2>Aniversariantes</h2>
      <List component="nav">
        <ListItem button divider onClick={() => history.push(Routes.MANAGE_COPS_BIRTHDAY_FOR_DAY)}>
          <ListItemText primary="Aniversariantes do dia" secondary="Consultar aniversariantes de uma data selecionada."/>
        </ListItem>
        <ListItem button divider onClick={() => history.push(Routes.MANAGE_COPS_BIRTHDAY_FOR_MONTH)}>
          <ListItemText primary="Aniversariantes do mês" secondary="Consultar aniversariantes do mês selecionado."/>
        </ListItem>
      </List>
    </>
  );
}