import Routes from "./Routes";
import {Add, Apps, Build, Domain, PermIdentity, TouchApp, CalendarToday} from "@material-ui/icons";

// Roles are mapped to the Scala Roles we maintain on our
// backend models. Even if you hack around the frontend
// the endpoints are protected anyway :)
export const ROLE_ADMIN = '1';
export const ROLE_APP_MANAGER = '2';
export const ROLE_PERMISSION_MANAGER = '3';
export const ROLE_MILITARY_UNITS = '4';
export const ROLE_USER_UPDATE = '5';
export const ROLE_EXTERNAL_USERS = '6';
export const ROLE_EXTERNAL_UNITS = '7';
export const ROLE_MANAGE_BIRTHDAY = '8';

// Here we map all the user routes and its required permissions
// Those routes are meant to be used on dynamic menu renderings
// but can be extracted to generate the Route components as well
export const menuRoutes = [
  {
    route: Routes.DASHBOARD,
    name: 'Meus Apps',
    requiredRoles: [],
    IconComponent: Apps
  },
  {
    route: Routes.PERMISSIONS_OPTIONS,
    name: 'Gerenciar Permissões',
    requiredRoles: [ROLE_ADMIN, ROLE_PERMISSION_MANAGER],
    IconComponent: Build,
  },
  {
    route: Routes.MANAGE_COPS_SEARCH,
    name: 'Atualizar Usuário',
    requiredRoles: [ROLE_ADMIN, ROLE_USER_UPDATE],
    IconComponent: PermIdentity,
  },
  {
    route: Routes.MANAGE_COPS_BIRTHDAY_OPTIONS,
    name: 'Aniversariantes',
    requiredRoles: [ROLE_ADMIN, ROLE_MANAGE_BIRTHDAY],
    IconComponent: CalendarToday,
  },
  {
    route: Routes.ADD_EXTERNAL_USER,
    name: 'Add Usuário Externo',
    requiredRoles: [ROLE_ADMIN, ROLE_EXTERNAL_USERS],
    IconComponent: Add,
  },
  {
    route: Routes.ADD_EXTERNAL_UNIT,
    name: 'Add Unidade Externa',
    requiredRoles: [ROLE_ADMIN, ROLE_EXTERNAL_UNITS],
    IconComponent: Add,
  },
  {
    route: Routes.MANAGE_APPS,
    name: 'Gerenciar Apps',
    requiredRoles: [ROLE_ADMIN, ROLE_APP_MANAGER],
    IconComponent: TouchApp,
  },
  {
    route: Routes.LIST_MILITARY_UNITS,
    name: 'Unidades Militares',
    requiredRoles: [ROLE_ADMIN, ROLE_MILITARY_UNITS],
    IconComponent: Domain
  }
]