import axios from 'axios';

export const postAdminInvalidateSessions = (userId) => {
  return axios.post(`/admin/tasks/logout/${userId}`);
};

export const getAdminActiveSessions = (userId) => {
  return axios.get(`/admin/tasks/sessions/${userId}`);
};

export const getAdminUserEvents = (userId) => {
  return axios.get(`/admin/tasks/activity/${userId}`);
}

export const deleteAdminRemoveAllPermissions = (userId) => {
  return axios.delete(`/admin/tasks/permissions/all/${userId}`);
}

export const postAdminResetPwEmail = (userId) => {
  return axios.post(`/admin/tasks/reset-pw/${userId}`);
}