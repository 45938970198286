import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/index";
import {Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card/index";
import CardContent from "@material-ui/core/CardContent/index";
import CardActions from "@material-ui/core/CardActions/index";
import Button from "@material-ui/core/Button/index";
import PropTypes from "prop-types";

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%'
  },
  cardContent: {
    flexGrow: 1,
  }
});

export default class ExternalAppCard extends Component {

  render() {
    const {classes, app, tempToken, onRenewTempToken} = this.props;
    const {name, description, baseUrl, authPath} = app;
    const onOpenApp = () => {
      const url = `${baseUrl}${authPath}?temporary_token=${tempToken}`
      window.open(url, '_blank');
      onRenewTempToken();
    }
    return (
      <Grid item sm={6} md={4}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2" style={{textAlign: 'center'}}>
              {name}
            </Typography>
            <Typography align="center">
              {description}
            </Typography>
            <CardActions style={{justifyContent: 'center'}}>
              <Button size="small" color="secondary" onClick={onOpenApp} >
                Abrir aplicativo em nova aba
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    );
  }

}

ExternalAppCard = withStyles(styles)(ExternalAppCard);

ExternalAppCard.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    authPath: PropTypes.string.isRequired,
  }).isRequired,
  tempToken: PropTypes.string.isRequired,
  onRenewTempToken: PropTypes.func.isRequired
};