import axios from 'axios';

/** Get the app from the currently logged in user */
export const getUserApps = () => {
  return axios.get('/api/apps');
};

/** Get the app from the given user */
export const getAppsByUserId = (userId) => {
  return axios.get(`/api/apps/user/${userId}`);
}

export const getAllApps = () => {
  return axios.get('/api/apps/list');
};

export const getApp = (id) => {
  return axios.get(`/api/apps/${id}`);
};

export const deleteApp = (id) => {
  return axios.delete(`/api/apps/${id}`)
}

export const putUpdateApp = (id, name, description, baseUrl, internalUrl, authPath) => {
  return axios.put( `/api/apps/${id}`, {name, description, baseUrl, internalUrl, authPath});
};

export const postCreateApp = (name, description, baseUrl, internalUrl, authPath) => {
  return axios.post('/api/apps', {name, description, baseUrl, internalUrl, authPath});
};

export const putRenewTemporaryToken = () => {
  return axios.put('/api/apps/auth/token', {});
}

export const getAppToken = (appId) => {
  return axios.get(`/api/apps/${appId}/token`);
};

export const postAppPermissions = (appId, userId, roleIds) => {
  return axios.post(`/api/permissions/app/${appId}/user/${userId}`, {roleIds});
};