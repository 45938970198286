import React from 'react';
import {API} from "../../../network/api";
import Routes from "../../../constants/Routes";
import ExternalAppForm from "./ExternalAppForm";
import {withRouter} from "react-router-dom";
import {Typography} from "@material-ui/core";

class AddExternalApp extends React.Component {

  state = {
    isSaving: false,
    app: {
      id: null,
      name: '',
      description: '',
      baseUrl: '',
      internalUrl: '',
      authPath: '',
    },
  };

  render() {
    const {app} = this.state;

    const onSave = (values, actions) => {
      const {history} = this.props;
      const {name, description, baseUrl, internalUrl, authPath} = values;
      API.postCreateApp(name, description, baseUrl, internalUrl, authPath).then(response => {
        history.push(Routes.MANAGE_APPS);
      }).catch(error => {
        actions.setSubmitting(false);
        console.error("Could not create app", error.response);
        const {data} = error.response;
        if (data) {
          const {error} = data;
          actions.setErrors({...error});
        }
      });
    };

    return (app &&
        <>
          <Typography component="h2" variant="h5">Adicionar Aplicação</Typography>
          <ExternalAppForm app={app} onSave={onSave}/>
        </>
    );

  }

}

AddExternalApp = withRouter(AddExternalApp);
export default AddExternalApp;