import React from 'react';
import {Route, Switch} from 'react-router-dom';

import LoginForm from './auth/LoginForm';
import RequestResetPassword from './auth/RequestResetPassword';
import ResetPassword from './auth/ResetPassword';
import Routes from "../constants/Routes";
import {AuthProvider} from "./auth/AuthProvider";
import {ProtectedRoute} from "./auth/ProtectedRoute";
import MainContainer from "./common/MainContainer";
import NavigationBar from "./common/NavigationBar";
import Sidebar from "./common/Sidebar";
import GlobalSettingsProvider from "./common/GlobalSettingsProvider";
import ErrorBoundary from "./common/ErrorBoundary";

export default function MainApp() {

  return (
    <ErrorBoundary>
      <GlobalSettingsProvider>
        <AuthProvider>
          <div>
            <Switch>
              <Route path={Routes.LOGIN} exact component={LoginForm}/>
              <Route path={Routes.RESET_PASSWORD_REQUEST} exact component={RequestResetPassword}/>
              <Route path={Routes.RESET_PASSWORD} component={ResetPassword}/>
              <ProtectedRoute>
                <NavigationBar/>
                <Sidebar/>
                <MainContainer/>
              </ProtectedRoute>
            </Switch>
          </div>
        </AuthProvider>
      </GlobalSettingsProvider>
    </ErrorBoundary>
  );

}
