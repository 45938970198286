import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {useGlobalSettings} from "./GlobalSettingsProvider";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

export default function NavigationBar() {
  const {toggleSidebar} = useGlobalSettings();
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={toggleSidebar}>
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            Central de Apps PMPB
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );

}
