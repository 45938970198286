import React, {useCallback} from 'react';
import {debounce} from 'throttle-debounce';
import {useSelect} from "downshift";
import {ListItem, ListItemText, TextField} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";

export default function AutocompleteSelect({items, selectedItem, label, onSearch, onItemSelected}) {

  return selectedItem
  ? <SelectedItem item={selectedItem} onDelete={() => onItemSelected(null)}/>
  : <AutocompleteInput items={items} label={label} onSearch={onSearch} onItemSelected={onItemSelected}/>

}

function AutocompleteInput({items, label, onSearch, onItemSelected}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(500, (value) => {
    onSearch(value)
  }), [])

  const {
    getLabelProps,
    getMenuProps,
    getItemProps,
    getToggleButtonProps,
  } = useSelect({items})

  return (
    <div>
      <TextField type="text"
                 name="autocomplete-select"
                 fullWidth onChange={e => debouncedSearch((e.target.value))} {...getLabelProps({label})}/>
      <button type="button" {...getToggleButtonProps()} value="hidden" style={{display: 'none'}}/>
      <div {...getMenuProps()}>
        {items.length ? (
          <Paper square>
            {items.map((item, index) => (
              <ListItem key={item.id} button {...getItemProps({item, index})} onClick={() => onItemSelected(item)}>
                <ListItemText primary={item.primary} secondary={item.secondary}/>
              </ListItem>
            ))}
          </Paper>
        ) : null}
      </div>
    </div>
  )
}

function SelectedItem({item, onDelete}) {
  return(
    <div style={{marginTop: 8}}>
      <Chip tabIndex={-1}
            label={item.primary}
            color="primary"
            onDelete={onDelete}/>
    </div>
  )
}