import React, { useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Button,
  Link,
  FormControl,
  Input,
  InputLabel,
  Typography
} from "@material-ui/core";

import AuthFormWrapper from "./AuthFormWrapper";
import Routes from "../../constants/Routes";
import {makeStyles} from "@material-ui/core/styles";
import useForm from "../../hooks/useForm";
import useQuery from "../../hooks/useQuery";
import {useGlobalNotifications} from "../common/GlobalNotifications";
import {postSignIn} from "../../network/auth";
import {useAuth} from "./AuthProvider";

const useStyles = makeStyles( theme => ({
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}))

export default function LoginForm() {

  const query = useQuery()
  const classes = useStyles()
  const {onSuccessfulLogin} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const {showFailure} = useGlobalNotifications()


  const [, onSubmit, onChange] = useForm(values => {
    const {email, password} = values;
    if (password.length < 8) {
      showFailure('Senha muito curta. Mínimo de 8 dígitos')
    } else {
      setIsLoading(true)
      const callbackUrl = query.get('callback_url')
      postSignIn(email, password, callbackUrl).then(res => {
        const {user, roles, temporaryToken} = res.data;
        // Once a temporary token is given
        // we are use the Auth app as a third-party auth service
        // we must redirect the user to the `callback_url`
        if (temporaryToken) {
          const url = `${callbackUrl}?temporary_token=${temporaryToken}`;
          console.log('redirecting to ', url)
          window.location.replace(url);
        } else {
          onSuccessfulLogin(user, roles)
        }
      }).catch(err => {
        const {error} = err.response.data
        showFailure(error || 'Credenciais inválidas.')
        setIsLoading(false)
      })
    }
  })

  const maybeRenderCallbackRedirect = () => {
    const callbackUrl = query.get('callback_url')
    return !callbackUrl
    ? null
    : (
      <Typography component="h6" variant="caption" align="center">
        Você será redirecionado para:<br/>
        <strong>{callbackUrl}</strong> <br/>
        <small>O autenticador PMPB validará essa requisição.</small>
      </Typography>
    )
  }

  return(
    <AuthFormWrapper>
      {maybeRenderCallbackRedirect()}
      <form className={classes.form} onSubmit={onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input name="email" id="email" type="email" onChange={onChange} autoComplete="email" autoFocus required/>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Senha</InputLabel>
          <Input id="password" type="password" name="password" onChange={onChange} required/>
        </FormControl>
        <Link component={RouterLink} to={Routes.RESET_PASSWORD_REQUEST} variant="body1">
          Esqueceu a senha?
        </Link>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isLoading}
          className={classes.submit}>
          Entrar
        </Button>
      </form>
    </AuthFormWrapper>
  )
}