import React, {useContext, useState} from 'react'
import {GlobalNotificationContext} from "../../common/GlobalNotifications";
import {useHistory} from "react-router-dom";
import useForm from "../../../hooks/useForm";
import {postCreateExternalUnit} from "../../../network/externalUsers";
import Routes from "../../../constants/Routes";
import {TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default function AddExternalUnitPage() {

  const [isLoading, set] = useState(false);
  const {showSuccess, showFailure} = useContext(GlobalNotificationContext);
  const history = useHistory();

  const [, onSubmit, onChange] = useForm((values) => {
    set(true);
    postCreateExternalUnit(values).then(response => {
      showSuccess('Unidade externa criada');
      set(false);
      history.push(Routes.MANAGE_COPS_SEARCH)
    }, error => {
      showFailure(error.response.data.error);
      set(false);
    });
  });

  return (
    <React.Fragment>
      <Typography component="h6" variant="h6">Adicionar Unidade Externa</Typography>
      <Typography>Unidades externas são qualquer outro setor do estado o qual não pertence a Policia Militar. Exemplo: Casa Civil</Typography>
      <form onSubmit={onSubmit} style={{padding: '16px 0'}}>
        <TextField type="text" name="name" label="Nome da unidade" required fullWidth onChange={onChange}/>
        <Button variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
                style={{float: 'right', marginTop: '10px', marginBottom: '10px'}}>
          {isLoading ? 'Enviando' : 'Salvar'}
        </Button>
      </form>
    </React.Fragment>
  );

}