import Grid from "@material-ui/core/Grid";
import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import React from "react";
import PropTypes from 'prop-types';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {API} from "../../../network/api";
import Loader from "../../common/Loader";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";

export default class PermissionsForm extends React.Component {

  state = {
    isLoading: false,
    isSaving: false,
    error: null,
    appRoles: [],
    userRoles: [],
  };

  componentDidMount() {
    const {user, app} = this.props;
    this.setState(() => ({isLoading: true}));
    API.getAppPermissions(app.id, user.id).then(response => {
      const {appRoles, userRoles} = response.data;
      this.setState(() => {
        return {appRoles, userRoles, isLoading: false};
      }, () => this.props.onSelectRoles(userRoles));
    }).catch(error => {
      this.setState(() => ({isLoading: false}));
      console.error("could not fetch permissions", error.response);
    })
  }

  _renderForm = () => {
    const {userRoles, appRoles, isLoading} = this.state;
    if (isLoading) {
      return <Loader/>
    }
    const userRoleIds = userRoles.map(r => r.id);
    const filteredAppRoles = appRoles.filter(role => !userRoleIds.includes(role.id));

    const addRoleToUser = (role) => {
      this.setState((prevState) => {
        return {userRoles: [...prevState.userRoles, role]};
      }, () => {
        this.props.onSelectRoles(this.state.userRoles);
      })
    };

    const removeRoleFromUser = (role) => {
      this.setState(prevState => {
        const newRoles = prevState.userRoles.filter(r => r.id !== role.id);
        return {userRoles: newRoles};
      }, () => {
        this.props.onSelectRoles(this.state.userRoles);
      });
    };

    return (
      <>
        <Grid item xs={12} sm={6}>
          <Card style={{marginTop: '10px'}}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Permissões Disponíveis
              </Typography>
              <List>
                {filteredAppRoles.map(role => (
                  <ListItem button key={role.id} onClick={() => addRoleToUser(role)}>
                    <ListItemText primary={role.name} secondary={role.description}/>
                    <ListItemIcon>
                      <KeyboardArrowRight/>
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
              { filteredAppRoles.length === 0 && (
                <Typography variant="caption" align="center">
                  Todas permissões adicionadas
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card style={{marginTop: '10px'}}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Permissões Do Usuário
              </Typography>
              <List>
                {userRoles.map(role => (
                  <ListItem button key={role.id} onClick={() => removeRoleFromUser(role)}>
                    <ListItemIcon>
                      <KeyboardArrowLeft/>
                    </ListItemIcon>
                    <ListItemText primary={role.name} secondary={role.description}/>
                  </ListItem>
                ))}
              </List>
              { userRoles.length === 0 && (
                <Typography variant="caption" align="center">
                  Nenhuma permissão selecionada
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </>
    );
  };

  render() {

    const {user, app} = this.props;
    const renderUserData = this.props.renderUserData && true;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {
            renderUserData && (
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Usuário
                  </Typography>
                  <Typography variant="h6" component="h6">
                    {`${user.rank} ${user.fullName}`} - {user.registration}
                  </Typography>
                </CardContent>
              </Card>
            )
          }
          <Card style={{marginTop: '10px'}}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Aplicativo
              </Typography>
              <Typography variant="h6" component="h3">
                {app.name}
              </Typography>
              <Typography color="textSecondary">
                Descrição
              </Typography>
              <Typography component="p">
                {app.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {this._renderForm()}

      </Grid>
    );
  }

}

PermissionsForm.propTypes = {
  user: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  onSelectRoles: PropTypes.func.isRequired,
  renderUserData: PropTypes.bool
};