import React, {
  createContext,
  useEffect,
  useContext, useCallback, useReducer,
} from 'react';

import * as Sentry from '@sentry/react';

import {
  deleteSignOut, ejectResponseInterceptor,
  getUserProfile, listenToUnauthorized,
} from '../../network/auth'
import Loader from '../common/Loader'
import {useHistory} from "react-router-dom";
import Routes from "../../constants/Routes";
import {useGlobalNotifications} from "../common/GlobalNotifications";

function enrichSentryData(userId, email, registration) {
  Sentry.configureScope(function (scope) {
    scope.setUser({
      id: userId,
      email,
      registration
    });
  });
}

const AuthContext = createContext(null)
AuthContext.displayName = 'AuthContext';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'profile_request': {
      return {
        ...state,
        isLoading: true,
      }
    }

    case 'profile_success': {
      return {
        ...state,
        user: action.user,
        roles: action.roles,
        isLoading: false,
      }
    }

    case 'logout':
    case 'profile_failure': {
      return {
        ...state,
        user: null,
        roles: [],
        isLoading: false,
      }
    }

    default:
      return state

  }
}

export const AuthProvider = ({children}) => {

  const history = useHistory()
  const {showFailure} = useGlobalNotifications()
  const [{user, roles, isLoading}, dispatch] = useReducer(authReducer, {
    user: null,
    roles: [],
    isLoading: true,
  })

  const onLogout = useCallback(() => {
    deleteSignOut().finally(() => {
      dispatch({type: 'logout'})
      history.replace(Routes.LOGIN)
    })
  }, [history])

  const onUnauthorized = useCallback(() => {
    dispatch({type: 'logout'})
    showFailure('Sua sessão expirou. Por favor, realize o login novamente.')
    history.replace(Routes.LOGIN)
  }, [history, showFailure])

  const onSuccessfulLogin = useCallback((user, roles) => {
    dispatch({type: 'profile_success', user, roles})
    enrichSentryData(user.id, user.email, user.registration)
    history.replace(Routes.DASHBOARD)
  }, [history])

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        const {user, roles} = response.data
        enrichSentryData(user.id, user.email, user.registration)
        dispatch({type: 'profile_success', user, roles})
      })
      .catch((_) => {
        dispatch({type: 'profile_failure'})
      })
  }, [])

  useEffect(() => {
    if (user) {
      const interceptorId = listenToUnauthorized(onUnauthorized)
      return () => ejectResponseInterceptor(interceptorId)
    }
  }, [user, onUnauthorized])

  return isLoading ? (
    <Loader/>
  ) : (
    <AuthContext.Provider value={{user, roles, onLogout, onSuccessfulLogin}}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === null) {
    throw new Error(
      'useAuth must be used within AuthProvider.' +
      '\n' +
      'Make sure to put <AuthProvider> on top of you component tree'
    )
  }
  return context
}