import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './AuthProvider'
import Routes from "../../constants/Routes";

/** Only render route component if user is signed in */
export const ProtectedRoute = ({ children, ...props }) => {
  const { user } = useAuth()
  return (
    <Route
      {...props}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}