import React from 'react';
import AppsOverviewContainer from "./AppsOverviewContainer";
import {Route, Switch} from "react-router-dom";
import Routes from "../../constants/Routes";
import NotFound from "../common/NotFound";

export default function Dashboard() {
  return (
    <Switch>
      <Route path={Routes.DASHBOARD} component={AppsOverviewContainer} exact/>
      <Route component={NotFound}/>
    </Switch>
  );
}