import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {Paper, Typography} from "@material-ui/core";
import logo from '../../images/brasao.jpg';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const AuthFormWrapper = ({classes, ...props}) => {

  return(
    <div className={classes.main}>
      <Paper className={classes.paper}>
        <img src={logo} alt="Logo PMPB" style={{width: '80px', marginBottom: '16px'}}/>
        <Typography component="h2" variant="h5">
          Autenticador PMPB
        </Typography>
        {props.children}

      </Paper>
    </div>
  );

};

export default withStyles(styles)(AuthFormWrapper);
