import React, {Component} from 'react';
import {Formik, Field, Form} from 'formik';
import {Paper, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

class ExternalAppForm extends Component {

  render() {
    const {app, onSave} = this.props;

    return (app &&
      <Formik onSubmit={onSave}
              initialValues={{...app}}
              render={({isSubmitting}) => {
                return (
                  <Form>
                    <Paper style={{padding: '16px 8px'}}>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                          <Field name="name" label="Nome do RootApp" component={OutlinedField}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field name="baseUrl" label="URL Base" component={OutlinedField}/>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="internalUrl"
                                 label="URL Interna (Utilize o IP interno do seu servidor. E.g. http://10.1.1.1:3000)"
                                 component={OutlinedField}/>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="authPath"
                                 label="Path para autenticação automática via Central de Aplicativos. E.g. /auth/callback)"
                                 component={OutlinedField}/>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="description" label="Descrição" component={OutlinedField}/>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Button color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                            type="submit" style={{marginTop: '10px', float: 'right'}}>
                      Salvar
                    </Button>
                  </Form>
                );
              }}
      />
    );
  }

}

const OutlinedField = ({field, form: {touched, errors}, ...props}) => {
  const error = {
    error: touched[field.name] !== undefined && errors[field.name] !== undefined,
    helperText: errors[field.name]
  };
  return (
    <TextField type="text" fullWidth {...error} {...field} {...props}/>
  );
};

ExternalAppForm.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    internalUrl: PropTypes.string.isRequired,
    authPath: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired
};

export default ExternalAppForm;