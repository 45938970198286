import React from 'react';
import AppForm from "./AppForm";
import {useHistory} from "react-router-dom";
import Routes from "../../../constants/Routes";

export default function UsersWithPermissionSelectAppPage() {

  const history = useHistory()

  const onAppSelected = (app) => {
    history.push(Routes.USERS_WITH_PERMISSIONS_IN_APP_PATH(app.id))
  }

  return(
    <>
      <h2>Selecione um app</h2>
      <AppForm onSelectApp={onAppSelected}/>
    </>
  )

}