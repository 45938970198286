import {useGlobalNotifications} from "../../common/GlobalNotifications";
import React, {useEffect, useState} from "react";
import {API} from "../../../network/api";
import {Typography} from "@material-ui/core";
import moment from "moment-timezone";
import {makeStyles} from "@material-ui/core/styles";

/** Print date in our local timezone */
function printDate(date) {
  return moment(date).tz('America/Maceio').format('DD/MM/YYYY hh:mm:ss')
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    justifyContent: 'center'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },

  cardWrapper: {
    display: 'flex',
  },
  timelineItem: {
    width: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dot: {
    width: 14,
    height: 17,
    background: theme.palette.primary.main,
    borderRadius: 7,
  },
  line: {
    width: 2,
    background: theme.palette.primary.main,
    height: '100%',
    content: ' ',
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 30,
  },
  card: {
    width: '100%',
    flexGrow: '2',
    padding: '16px',
    background: '#FFFFFF',
    borderRadius: 10,
    boxShadow: '0px 16px 15px -10px rgba(105, 96, 215, 0.0944602)',
    marginBottom: 16,
    '&:first-child': {
      marginTop: 50,
    }
  },
  header: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '19px',
    marginBottom: 5,
    marginTop: 0
  },
  content: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '22px',
    color: '#2B2862',
    margin: 0,
  }
}));

export default function UserActivity({userId}) {

  const {showFailure} = useGlobalNotifications();
  const [sessions, setSessions] = useState([])
  const [events, setEvents] = useState([])
  const classes = useStyles()


  useEffect(() => {
    const getSessions = () => {
      API.getAdminActiveSessions(userId).then((res) => {
        const {sessions} = res.data;
        setSessions(sessions)
      }).catch(() => {
        showFailure('Não foi possível carregar sessões.')
      })
    }

    const getEvents = () => {
      API.getAdminUserEvents(userId).then((res) => {
        const {events} = res.data;
        console.log('data: ', res.data)
        setEvents(events)
      }).catch(() => {
        showFailure('Não foi possível carregar atividades recentes.')
      })
    }

    getSessions()
    getEvents()
  }, [showFailure, userId])

  return (
    <>
      <div className={`${classes.wrapper} ${classes.alignCenter}`}>
        <Typography component="h2" variant="h5" gutterBottom style={{margin: '18px 0'}}>
          Sessões Ativas
        </Typography>
      </div>

      <div className={classes.wrapper}>

        <div className={classes.cards}>
          { sessions.map(({id, createdAt, app}) => {
            return (
              <div key={id} className={classes.cardWrapper}>
                <div className={classes.timelineItem}>
                  <div className={classes.dot}/>
                  <div className={classes.line}/>
                </div>
                <div className={classes.card}>
                  <h4 className={classes.header}>{printDate(createdAt)}</h4>
                  <p className={classes.content}>Aplicativo: {app}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className={`${classes.wrapper} ${classes.alignCenter}`}>
        <Typography component="h2" variant="h5" gutterBottom style={{margin: '18px 0'}}>
          Atividades Recentes
        </Typography>
      </div>

      <div className={classes.wrapper}>

        <div className={classes.cards}>
          { events.map(({key, createdAt, comment}) => {
            return (
              <div key={`${key}-${createdAt}`} className={classes.cardWrapper}>
                <div className={classes.timelineItem}>
                  <div className={classes.dot}/>
                  <div className={classes.line}/>
                </div>
                <div className={classes.card}>
                  <h4 className={classes.header}>{printDate(createdAt)}</h4>
                  <p className={classes.content}>
                    {comment}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}