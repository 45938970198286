import React from 'react';
import {
  Button, Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import {Delete, Edit, VpnKey} from '@material-ui/icons';
import {API} from "../../../network/api";
import {withRouter} from "react-router-dom";
import Routes from "../../../constants/Routes";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  modal: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  modalContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

class ExternalAppsList extends React.Component {

  state = {
    isLoading: false,
    isLoadingToken: false,
    appLoadingToken: null,
    secretToken: null,
    isDeletingApp: false,
    appId: null,
    apps: []
  };

  componentDidMount() {
    this.setState(state => ({...state, isLoading: true}));
    API.getAllApps().then(response => {
      const {apps} = response.data;
      this.setState(state => ({...state, apps, isLoading: false}));
    }).catch(error => {
      console.error("Could not load apps:", error.response);
      this.setState(state => ({...state, isLoading: false}));
    })
  }

  getSecretToken = (app) => {
    const {isLoadingToken} = this.state;
    if (isLoadingToken) return;
    this.setState(() => ({isLoadingToken: true, appLoadingToken: app}));
    API.getAppToken(app.id).then(response => {
      const {secretToken} = response.data;
      this.setState(() => ({
        isLoadingToken: false,
        secretToken
      }));
    }).catch(error => {
      this.setState(() => ({
        isLoadingToken: false,
        appLoadingToken: null,
        secretToken: null
      }));
      alert(error.response.data.error);
    });
  };

  openDeleteAppModal = (appId) => {
    this.setState(state => ({
      ...state,
      isDeletingApp: true,
      appId: appId
    }));
  };

  _renderAppRow = (app) => {
    const {history} = this.props;
    const {isLoadingToken, appIdLoadingToken} = this.state;
    return (
      <TableRow key={app.id}>
        <TableCell>{app.id}</TableCell>
        <TableCell>{app.name}</TableCell>
        <TableCell>{app.baseUrl}</TableCell>
        <TableCell colSpan={3}>
          <Button size="small"
                  color="primary"
                  startIcon={<VpnKey/>}
                  onClick={() => this.getSecretToken(app)}
                  disabled={appIdLoadingToken && appIdLoadingToken === app.id && isLoadingToken}>
            Ver Token
          </Button>
          <Button size="small"
                  color="secondary"
                  startIcon={<Edit/>}
                  onClick={() => history.push(Routes.MANAGE_EXTERNAL_APP_PATH(app.id))}>
            Editar
          </Button>
          <Button size="small"
                  color="secondary"
                  startIcon={<Delete/>}
                  onClick={() => this.openDeleteAppModal(app.id)}
                  disabled={app.id === 1}>
            {/* Bem Hacky, só para bloquear a deleção do Auth em si mesmo */}
            {/* Auth é sempre o app com ID no banco */}
            Deletar App
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  _renderModal = () => {
    const {secretToken, appLoadingToken} = this.state;
    const {classes} = this.props;
    const isOpen = secretToken !== null && appLoadingToken !== null;
    const onClose = () => {
      this.setState(() => ({secretToken: null, appLoadingToken: null}))
    };

    return (
      <Modal open={isOpen} onClose={onClose}>
        <div className={`${classes.modal} ${classes.modalContainer}`}>
          <Typography variant="h6">
            {appLoadingToken && appLoadingToken.name}
          </Typography>
          <Typography variant="body1" style={{wordBreak: 'break-all'}}>
            {secretToken}
          </Typography>
          <Typography variant="body2" style={{marginTop: '20px'}}>
            Copie o token e salve em um lugar seguro na sua aplicação.
          </Typography>
          <Button size="small" color="primary" onClick={onClose}>Ok</Button>
        </div>
      </Modal>
    );
  };

  _renderDeleteApp = () => {
    const {isDeletingApp, appId} = this.state;
    if (appId && isDeletingApp) {
      const {classes} = this.props;
      const onClose = () => {
        this.setState(state => ({
          ...state,
          appId: null,
          isDeletingApp: false
        }))
      }

      const deleteApp = () => {
        API.deleteApp(appId).then(_ => {
          this.setState(state => ({
            ...state,
            isDeletingApp: false,
            appId: null,
            apps: state.apps.filter(app => app.id !== appId)
          }))
        }).catch(err => {
          alert('Erro ao deletar app.')
          console.error("Nao foi possível deletar o app", err)
          onClose()
        })
      }

      return (
        <Modal open={isDeletingApp} onClose={onClose}>
          <div className={`${classes.modal} ${classes.modalContainer}`}>
            <Typography variant="h5" style={{marginBottom: 8}}>
              Você tem certeza que deseja deletar o app?
            </Typography>
            <Button size="small" color="default" onClick={onClose}>Cancelar</Button>
            <Button size="small" color="primary" onClick={deleteApp}>Deletar</Button>
          </div>
        </Modal>
      );
    } else {
      return null;
    }
  }

  render() {

    const {classes, history} = this.props;
    const {apps} = this.state;

    return (
      <div>
        <Typography gutterBottom variant="h5" component="h2">
          Gerenciar Aplicativos
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={() => history.push(Routes.ADD_EXTERNAL_APP)}>
              Adicionar Novo App
            </Button>
          </Grid>
        </Grid>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>URL Base</TableCell>
                <TableCell colSpan={3}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apps.map(this._renderAppRow)}
            </TableBody>
          </Table>
        </Paper>
        {this._renderModal()}
        {this._renderDeleteApp()}
      </div>
    );
  }

}

ExternalAppsList = withRouter(withStyles(styles)(ExternalAppsList));

export default ExternalAppsList;