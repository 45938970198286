import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {updateTopLevelTitle} from "../../actions/global";
import {Typography} from "@material-ui/core";

class NotFound extends Component {
  componentDidMount() {
    this.props.updateTopLevelTitle('');
  }

  render() {
    return (
      <div className="col-md-12" style={{textAlign: 'center'}}>
        <Typography variant="h2">404</Typography>
        <Typography variant="h4">Página não encontrada</Typography>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({updateTopLevelTitle}, dispatch);
};

NotFound = connect(null, mapDispatchToProps)(NotFound);

export default NotFound;