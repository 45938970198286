import React from 'react';
import {List, ListItem, ListItemText} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import Routes from "../../../constants/Routes";
import PaddingPaper from "../../common/PaddingPaper";

export const PermissionsOptionsPage = () => {
  const history = useHistory();
  return(
    <>
      <h2>Gerenciar Permissões</h2>
      <PaddingPaper>
        <List component="nav">
          <ListItem button divider onClick={() => history.push(Routes.MANAGE_USER_PERMISSIONS)}>
            <ListItemText primary="Adicionar Permissões" secondary="Adicionar permissões de um app específico a um usuário"/>
          </ListItem>
          <ListItem button divider onClick={() => history.push(Routes.LIST_USER_PERMISSIONS_SEARCH)}>
            <ListItemText primary="Listar Permissões de Usuário" secondary="Listar todas as permissões do usuário em todos os apps que ele tem cadastro"/>
          </ListItem>
          <ListItem button divider onClick={() => history.push(Routes.USERS_WITH_PERMISSION_SELECT_APP)}>
            <ListItemText primary="Listar usuários com permissão específica" secondary="Lista todos os usuários de um app específico que possuem a permissão selecionada"/>
          </ListItem>
        </List>
      </PaddingPaper>
    </>
  );
};

export default PermissionsOptionsPage;