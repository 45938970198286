import React, {useContext, useEffect, useState} from 'react';
import Loader from '../common/Loader';
import {useParams, useHistory, Link as RouterLink} from 'react-router-dom';
import AuthFormWrapper from "./AuthFormWrapper";
import {Button, Link, TextField, Typography} from "@material-ui/core";

import {postResetPassword, postValidateResetToken} from "../../network/resetPassword";
import {GlobalNotificationContext} from "../common/GlobalNotifications";
import Routes from "../../constants/Routes";

export default function ResetPassword() {
  const {token} = useParams();
  const history = useHistory();
  const {showSuccess, showFailure} = useContext(GlobalNotificationContext);
  const [isValidatingToken, setIsValidatingToken] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordError, setPasswordError] = useState(null);

  const isPasswordValid = password.length >= 8 && password === passwordConfirmation;

  useEffect(() => {
    // only perform side effects if still mounted
    let isMounted = true;
    const setTokenValidity = (isValid) => {
      if (!isMounted) return;
      setIsValidatingToken(false);
      setIsTokenValid(isValid);
    };
    setIsValidatingToken(true);
    postValidateResetToken(token).then(
      response => setTokenValidity(true),
      error => setTokenValidity(false)
    );
    return () => {
      isMounted = false;
    }
  }, [token]);

  const onResetPassword = (event) => {
    event.preventDefault();
    setIsResettingPassword(true);
    postResetPassword(token, password, passwordConfirmation).then(response => {
      const {redirectUrl} = response.data;
      // Users coming from another application
      // we redirect them back to the originator app
      if (redirectUrl) {
        window.location = redirectUrl;
      } else {
        showSuccess("Sua senha foi renovada com sucesso.");
        history.push(Routes.LOGIN);
      }
    }, error => {
      const {message} = error.response.data;
      showFailure(message || "Não foi possível mudar sua senha. Solicite um novo email de reset.");
      setPasswordError(error.response.data.error || null);
      console.error("Erro ao resetar de senha", error);
    });
  };

  if (isValidatingToken) {
    return <Loader/>
  } else {
    const submitButton = (isTokenValid && isValidatingToken) || isResettingPassword
      ? <Loader/>
      : (
        <Button style={{marginTop: '10px', float: 'right'}}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isPasswordValid}>
          Resetar Senha
        </Button>
      );

    const tokenValidationMessage = !isValidatingToken && !isTokenValid && 'Link inválido. Solicite outro email de reset';

    const emailValidationMessage =
      password !== '' && password.length < 8
      ? 'Senha precisa ter no mínimo 8 caracteres.'
      : passwordConfirmation !== password
        ? 'Confirmação da senha precisa ser igual a senha.'
        : null;

    return (

      <AuthFormWrapper>
        <Typography component="h3" variant="h6">
          Resetar Senha
        </Typography>
        {tokenValidationMessage}
        {emailValidationMessage}
        <label>{passwordError}</label>
        { isTokenValid && (
          <form onSubmit={onResetPassword}>
            <TextField type="password"
                       placeholder="Nova Senha"
                       fullWidth
                       required
                       name="password"
                       onChange={(e) => { setPassword(e.target.value) }}/>
            <TextField type="password"
                       placeholder="Repita a nova senha"
                       required
                       fullWidth
                       name="passwordConfirmation"
                       style={{marginTop: '10px'}}
                       onChange={(e) => { setPasswordConfirmation(e.target.value) }}/>
            {submitButton}
          </form>
        )}
        <Link component={RouterLink}
              to="/app/login"
              variant="body1" style={{display: 'block', marginTop: '10px', textAlign: 'right', width: '100%'}}>
          Retornar para login
        </Link>
      </AuthFormWrapper>
    );
  }
}