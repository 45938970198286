import React from "react";
import {API} from "../../../network/api";
import Loader from "../../common/Loader";
import {List, ListItem, ListItemText} from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from "prop-types";
import PaddingPaper from "../../common/PaddingPaper";

export default class AppForm extends React.Component {

  state = {
    isLoading: true,
    apps: []
  };

  componentDidMount() {
    API.getAllApps().then(response => {
      const {apps} = response.data;
      this.setState(() => ({isLoading: false, apps}));
    }).catch(error => {
      this.setState(() => ({isLoading: false}));
      console.error("Could not load apps:", error.response);
    });
  }

  onAppClick = (app) => {
    this.props.onSelectApp(app);
  };

  render() {
    const {isLoading, apps} = this.state;
    const {selectedApp} = this.props;
    if (isLoading) {
      return <Loader/>
    }
    return (
      <PaddingPaper>
        <List component="nav">
          {apps.map(app => (
              <ListItem button divider key={app.id} onClick={() => this.onAppClick(app)}>
                <ListItemText primary={app.name} secondary={app.description}/>
                {selectedApp && selectedApp.id === app.id && (
                    <ListItemSecondaryAction>
                      <CheckIcon/>
                    </ListItemSecondaryAction>
                )}
              </ListItem>
          ))}
        </List>
      </PaddingPaper>
    );
  }

}

AppForm.propTypes = {
  selectedApp: PropTypes.object,
  onSelectApp: PropTypes.func.isRequired
};