class Routes {

  static APP_ROOT = '/app'
  static LOGIN = '/app/login'
  static DASHBOARD = '/app/dashboard'
  static ADMIN_ROUTES = '/app/admin'
  static PERMISSIONS_OPTIONS = '/app/admin/permissions/options'
  static MANAGE_USER_PERMISSIONS = '/app/admin/permissions'
  static LIST_USER_PERMISSIONS_SEARCH = '/app/admin/permissions/user'
  static LIST_USER_PERMISSIONS = '/app/admin/permissions/user/:registration'
  static USERS_WITH_PERMISSION_SELECT_APP = '/app/admin/permissions/app-user'
  static USERS_WITH_PERMISSION_IN_APP = '/app/admin/permissions/app-user/:appId'
  static USERS_WITH_PERMISSION_IN_APP_LIST_USERS = '/app/admin/permissions/app-user/:appId/:roleId/users'
  static MANAGE_COPS_SEARCH = '/app/admin/cops/search'
  static MANAGE_COPS_BIRTHDAY_OPTIONS = '/app/admin/cops/bday'
  static MANAGE_COPS_BIRTHDAY_FOR_DAY = '/app/admin/cops/bday/day'
  static MANAGE_COPS_BIRTHDAY_FOR_MONTH = '/app/admin/cops/bday/month'
  static MANAGE_COPS_EDIT = '/app/admin/cops/:registration/edit'
  static MANAGE_APPS = '/app/admin/externalapps/manage'
  static EDIT_EXTERNAL_APP = '/app/admin/externalapps/:id/edit'
  static ADD_EXTERNAL_APP = '/app/admin/externalapps/new'
  static LIST_MILITARY_UNITS = '/app/admin/units'
  static RESET_PASSWORD_REQUEST = "/app/login/password/request"
  static RESET_PASSWORD = "/app/login/password/reset/:token"
  static MILITARY_UNIT_USERS = '/app/admin/units/:id/users'
  static ADD_EXTERNAL_USER = '/app/admin/external-user/add'
  static ADD_EXTERNAL_UNIT = '/app/admin/external-units/add'

  /** Dynamic paths */
  static MILITARY_UNIT_DETAIL_PATH = (id) => Routes.MILITARY_UNIT_USERS.replace(':id', id)
  static MANAGE_EXTERNAL_APP_PATH = (id) => Routes.EDIT_EXTERNAL_APP.replace(':id', id)
  static LIST_USER_PERMISSIONS_PATH = (registration) => Routes.LIST_USER_PERMISSIONS.replace(':registration', registration);
  static USERS_WITH_PERMISSIONS_IN_APP_PATH = (appId) => Routes.USERS_WITH_PERMISSION_IN_APP.replace(':appId', appId)
  static USERS_WITH_PERMISSIONS_IN_APP_LIST_USERS_PATH = (appId, roleId) => {
    return Routes.USERS_WITH_PERMISSION_IN_APP_LIST_USERS.replace(':appId', appId).replace(':roleId', roleId)
  }
  static MANAGE_COPS_EDIT_PATH = (registration) => Routes.MANAGE_COPS_EDIT.replace(':registration', registration)

}

export default Routes;