import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {CssBaseline} from '@material-ui/core'
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/es/styles"
import * as Sentry from '@sentry/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt-br'

// Redux Binders
import {Provider} from 'react-redux';
// redux data store
import reduxStore from './store';
import MainApp from './components/MainApp';
import GlobalNotifications from "./components/common/GlobalNotifications";

Sentry.init({dsn: process.env.REACT_APP_SENTRY_ESN});

moment.locale("pt-br");

/** Custom Material-UI Theming */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_COLOR,
    }
  },
  typography: {
    useNextVariants: true,
  },
});

export const RootApp = () => {
  return(
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale='pt-br'>
        <Provider store={reduxStore}>
          <CssBaseline/>
          <GlobalNotifications>
            <Router>
              <Route path="/" component={MainApp}/>
            </Router>
          </GlobalNotifications>
        </Provider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
};