import React from 'react';
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  loader: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}))

const Loader = ({size = null, position = 'center'}) => {
  const finalSize = size || 24;
  const margin = (finalSize / 2) * -1;
  const classes = useStyles()
  let styles = {
    'center': {
      marginLeft: margin,
      marginTop: margin
    },
    'relative': {
      position: 'relative',
      display: 'inline-block',
      marginLeft: 8,
      top: 'unset',
      left: 'unset'
    }
  }
  const style = styles[position] || {}
  return <CircularProgress size={finalSize} className={classes.loader} style={style}/>;
};


export default Loader;