import axios from 'axios';

export const getAppPermissions = (appId, userId) => {
  return axios.get(`/api/permissions/app/${appId}/user/${userId}`);
};

export const getPermissionsForAppOnly = (appId) => {
  return axios.get(`/api/permissions/app/${appId}`)
}

export const getUsersWithAppRole = (appId, roleId) => {
  return axios.get(`/api/permissions/app/${appId}/role/${roleId}/users`)
}