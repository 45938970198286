import React from 'react';
import * as Sentry from '@sentry/react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with an error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // Report to Sentry
    console.error('erro capturado: ', error)
    Sentry.captureException(error);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div style={{padding: 16}}>
          <h4>Algo deu errado 😅. Por favor, tire um print e informe esse erro ao time de TI.</h4>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}