import React, {useContext, useState} from 'react';
import {StepLabel, Stepper, Typography} from "@material-ui/core";
import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";
import CopSearchForm from "./CopSearchForm";
import AppForm from "./AppForm";
import PermissionsForm from "./PermissionsForm";
import * as API from "../../../network/apps";
import {makeStyles} from "@material-ui/core/styles";
import {GlobalNotificationContext} from "../../common/GlobalNotifications";
import PaddingPaper from "../../common/PaddingPaper";

const steps = ['Busque um militar', 'Escolha um app', 'Gerencie permissões'];

const useStyles = makeStyles({
  withPadding: {
    padding: '8px',
    marginBottom: '16px',
  },
  stepper: {
    padding: `8px 0 8px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: '8px',
    marginLeft: '8px',
  },
});

export default function PermissionsPage() {

  const [user, setUser] = useState(null);
  const [app, setApp] = useState(null);
  const [roles, setRoles] = useState([]);
  const [step, setStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const classes = useStyles();
  const {showSuccess, showFailure} = useContext(GlobalNotificationContext);

  const onNext = () => {
    setStep(step => step + 1);
  };

  const onBack = () => {
    setStep(step => step - 1);
  };

  const _renderCurrentStep = () => {
    switch (step) {
      case 0:
        return <CopSearchForm selectedCop={user} onSelectCop={cop => setUser(cop)}/>;
      case 1:
        return <AppForm selectedApp={app} onSelectApp={app => setApp(app)}/>;
      case 2:
        return <PermissionsForm user={user} app={app} renderUserData={true} onSelectRoles={roles => setRoles(roles)}/>;
      default:
        return <h1>Step não encontrado.</h1>;
    }
  };

  const savePermissions = () => {
    setIsSaving(true);
    API.postAppPermissions(app.id, user.id, roles.map(r => r.id)).then(() => {
      setIsSaving(false);
      showSuccess('Permissões Salvas');
    }).catch(error => {
      showFailure('Erro ao salvar permissões');
      setIsSaving(false);
      console.error("Could not save permissions", error);
    });
  };

  const stepState = () => {
    let canGoNext = false;
    let canSave = false;
    switch (step) {
      case 0:
      case 1:
        canGoNext = user !== null;
        break;
      case 2:
        canGoNext = false;
        canSave = user !== null && app !== null;
        break;
      default:
        console.error("Nops! This step is not allowed: " + step);
    }
    return {canGoNext, canSave};
  };

  const {canGoNext, canSave} = stepState();
  const actionButtonFn = canSave ? savePermissions : onNext;

  return (
    <>
      <PaddingPaper className={classes.withPadding}>
        <Typography component="h2" variant="h5">Gerenciar Permissões</Typography>
        <Stepper activeStep={step} className={classes.stepper}>
          {steps.map(stepName => (
              <Step key={stepName}>
                <StepLabel>{stepName}</StepLabel>
              </Step>
          ))}
        </Stepper>
      </PaddingPaper>
      {_renderCurrentStep()}
      <div className={classes.buttons}>
        {step !== 0 && (
          <Button onClick={onBack} className={classes.button}>Voltar</Button>
        )}
        <Button variant="contained"
                color="primary"
                onClick={actionButtonFn}
                className={classes.button}
                disabled={isSaving || (!canGoNext && !canSave)}>
          {step === (steps.length - 1) ? 'Salvar' : 'Avançar'}
        </Button>
      </div>
    </>
  );

}