import {
  FormControl,
  InputLabel, MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import React, {useEffect, useMemo, useReducer} from "react";
import Routes from "../../../constants/Routes";
import {Link} from "react-router-dom";
import moment from "moment-timezone";
import PaddingPaper from "../../common/PaddingPaper";
import {makeStyles} from "@material-ui/styles";

const rankSequence = Object.fromEntries(['CEL', 'TC', 'MAJ', 'CAP', '1º TEN', '2º TEN', 'ASP', 'CFO 3º', 'CFO 2º', 'CFO 1º', 'ST', '1º SGT', '2º SGT', '3º SGT', 'AL SGT', 'CB', 'SD', 'REC', ''].map((rank, index) => [rank, index]))

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}));

function sortByRank(cops) {
  return cops.slice().sort((a, b) => {
    const valueA = rankSequence[a.rank] || 0
    const valueB = rankSequence[b.rank] || 0
    return valueA - valueB
  })
}

const copListReducer = (state, action) => {
  switch(action.type) {

    case 'copsUpdate': {
      return {
        ...state,
        cops: sortByRank(action.cops),
        sortBy: 'sortByRank',
      }
    }

    case 'sortByRank': {
      return {
        ...state,
        sortBy: action.type,
        cops: sortByRank(state.cops)
      }
    }

    case 'sortByName': {
      return {
        ...state,
        sortBy: action.type,
        cops: state.cops.slice().sort((a, b) => {
          const nameA = a.fullName.toLowerCase()
          const nameB = b.fullName.toLowerCase()
          return nameA < nameB ? -1 : 1
        })
      }
    }

    case 'sortByRegistration': {
      return {
        ...state,
        sortBy: action.type,
        cops: state.cops.slice().sort((a, b) => {
          const numberA = parseInt(a.registration.split('-')[0])
          const numberB = parseInt(b.registration.split('-')[0])
          return numberA < numberB ? -1 : 1
        })
      }
    }

    case 'sortByBday': {
      return {
        ...state,
        sortBy: action.type,
        cops: state.cops.slice().sort((a, b) => {
          const dateA = moment(a.birthdate)
          const dateB = moment(b.birthdate)
          return dateA - dateB
        })
      }
    }

    case 'showActiveOnly': {
      return {
        ...state,
        showActiveOnly: true,
      }
    }

    case 'showAll': {
      return {
        ...state,
        showActiveOnly: false,
      }
    }

    default: return state

  }
}

export default function CopsList({cops}) {

  const classes = useStyles();

  const [{showActiveOnly, sortBy, cops: sortedCops}, dispatch] = useReducer(copListReducer, {
    sortBy: 'sortByRank',
    showActiveOnly: true,
    cops: sortByRank(cops)
  })

  const filteredCops = useMemo(() => {
    return showActiveOnly ? sortedCops.filter(c => c.situation === 'Ativa') : sortedCops
  }, [showActiveOnly, sortedCops])

  useEffect(() => {
    dispatch({type: 'copsUpdate', cops})
  }, [cops])

  const onSelectChange = (e) => {
    dispatch({type: e.target.value})
  }

  return (
    <PaddingPaper>
      <div className={classes.wrapper}>
        <Typography gutterBottom variant="caption">
          Total de militares: {filteredCops && filteredCops.length}
        </Typography>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="show-active-only">Mostrar:</InputLabel>
            <Select
              labelId="show-active-only"
              id="show-active-only-select"
              value={showActiveOnly ? 'showActiveOnly' : 'showAll'}
              onChange={onSelectChange}
              label="Filtrar Por"
            >
              <MenuItem value={'showActiveOnly'}>Militares da Ativa</MenuItem>
              <MenuItem value={'showAll'}>Todos</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="sort-list-by">Ordernar Por:</InputLabel>
            <Select
              labelId="sort-list-by"
              id="sort-list-by-select"
              value={sortBy}
              onChange={onSelectChange}
              label="Ordernar Por"
            >
              <MenuItem value={'sortByRank'}>Patente</MenuItem>
              <MenuItem value={'sortByName'}>Nome</MenuItem>
              <MenuItem value={'sortByRegistration'}>Matricula</MenuItem>
              <MenuItem value={'sortByBday'}>Data de Nasc.</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Matrícula</TableCell>
              <TableCell>Patente</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Data de nascimento</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Situação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredCops.map((c) => {
                const copUrl = Routes.MANAGE_COPS_EDIT_PATH(c.registration)
                return (
                  <TableRow key={c.registration}>
                    <TableCell>{c.registration}</TableCell>
                    <TableCell>{c.rank}</TableCell>
                    <TableCell>
                      <Link to={copUrl}>{c.fullName}</Link>
                    </TableCell>
                    <TableCell>{c.email}</TableCell>
                    <TableCell>{moment(c.birthdate).utc().format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{c.phone}</TableCell>
                    <TableCell>{c.situation}</TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </PaddingPaper>
  )

}