import React from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';

import Dashboard from '../dashboard/Dashboard';
import NotFound from './NotFound';
import Routes from "../../constants/Routes";
import {makeStyles} from "@material-ui/core/styles";
import {RequireRoleRoute} from "../auth/RequireRoleRoute";
import {
  ROLE_ADMIN,
  ROLE_APP_MANAGER,
  ROLE_EXTERNAL_UNITS,
  ROLE_EXTERNAL_USERS, ROLE_MANAGE_BIRTHDAY,
  ROLE_MILITARY_UNITS,
  ROLE_PERMISSION_MANAGER,
  ROLE_USER_UPDATE
} from "../../constants/Roles";
import ExternalAppsList from "../admin/externalApps/ExternalAppsList";
import EditExternalApp from "../admin/externalApps/EditExternalApp";
import AddExternalApp from "../admin/externalApps/AddExternalApp";
import PermissionsOptionsPage from "../admin/permissions/PermissionsOptionsPage";
import PermissionsPage from "../admin/permissions/PermissionsPage";
import CopSearchForm from "../admin/permissions/CopSearchForm";
import UserPermissionsListPage from "../admin/permissions/UserPermissionsListPage";
import UsersWithPermissionSelectAppPage from "../admin/permissions/UsersWithPermissionSelectAppPage";
import UsersWithPermissionInAppSelectPermissionPage
  from "../admin/permissions/UsersWithPermissionInAppSelectPermissionPage";
import UsersWithPermissionInAppListUsersPage from "../admin/permissions/UsersWithPermissionInAppListUsersPage";
import {EditCopSearchPage} from "../admin/cops/EditCopSearchPage";
import MilitaryUnits from "../admin/permissions/MilitaryUnits";
import MilitaryUnitUsers from "../admin/permissions/MilitaryUnitUsers";
import AddExternalUserPage from "../admin/externalUsers/AddExternalUserPage";
import AddExternalUnitPage from "../admin/externalUsers/AddExternalUnitPage";
import ErrorBoundary from "./ErrorBoundary";
import EditCopPage from "../admin/cops/EditCopPage";
import BirthdayOptionsPage from "../admin/cops/BirthdayOptionsPage";
import BirthdayListPage from "../admin/cops/BirthdayListPage";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: '8px',
    [theme.breakpoints.up(600 + theme.spacing(2 * 2))]: {
      width: '100%',
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
}))

export default function MainContainer() {
  const classes = useStyles();
  const history = useHistory()
  return (
    <ErrorBoundary>
      <div className={classes.layout}>
          <Switch>
            <Route path={Routes.APP_ROOT} exact={true}>
              <Redirect to={Routes.DASHBOARD}/>
            </Route>
            <Route path={Routes.DASHBOARD} component={Dashboard}/>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_APP_MANAGER]} path={Routes.MANAGE_APPS} exact={true}>
              <ExternalAppsList/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_APP_MANAGER]} path={Routes.EDIT_EXTERNAL_APP}
                              exact={true}>
              <EditExternalApp/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_APP_MANAGER]} path={Routes.ADD_EXTERNAL_APP}
                              exact={true}>
              <AddExternalApp/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_PERMISSION_MANAGER]} path={Routes.PERMISSIONS_OPTIONS}
                              exact={true}>
              <PermissionsOptionsPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_PERMISSION_MANAGER]}
                              path={Routes.MANAGE_USER_PERMISSIONS} exact={true}>
              <PermissionsPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_PERMISSION_MANAGER]}
                              path={Routes.LIST_USER_PERMISSIONS_SEARCH} exact={true}>
              <CopSearchForm onSelectCop={cop => history.push(Routes.LIST_USER_PERMISSIONS_PATH(cop.registration))}/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_PERMISSION_MANAGER]} path={Routes.LIST_USER_PERMISSIONS}
                              exact>
              <UserPermissionsListPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_PERMISSION_MANAGER]}
                              path={Routes.USERS_WITH_PERMISSION_SELECT_APP} exact>
              <UsersWithPermissionSelectAppPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_PERMISSION_MANAGER]}
                              path={Routes.USERS_WITH_PERMISSION_IN_APP} exact>
              <UsersWithPermissionInAppSelectPermissionPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_PERMISSION_MANAGER]}
                              path={Routes.USERS_WITH_PERMISSION_IN_APP_LIST_USERS} exact>
              <UsersWithPermissionInAppListUsersPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_USER_UPDATE]} path={Routes.MANAGE_COPS_SEARCH} exact={true}>
              <EditCopSearchPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_USER_UPDATE]} path={Routes.MANAGE_COPS_EDIT} exact={true}>
              <EditCopPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_MANAGE_BIRTHDAY]} path={Routes.MANAGE_COPS_BIRTHDAY_OPTIONS} exact={true}>
              <BirthdayOptionsPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_MANAGE_BIRTHDAY]} path={Routes.MANAGE_COPS_BIRTHDAY_FOR_DAY} exact={true}>
              <BirthdayListPage bdayQueryType={'day'}/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_MANAGE_BIRTHDAY]} path={Routes.MANAGE_COPS_BIRTHDAY_FOR_MONTH} exact={true}>
              <BirthdayListPage bdayQueryType={'month'}/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_MILITARY_UNITS]} path={Routes.LIST_MILITARY_UNITS}
                              exact={true}>
              <MilitaryUnits/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_MILITARY_UNITS]} path={Routes.MILITARY_UNIT_USERS}
                              exact={true}>
              <MilitaryUnitUsers/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_EXTERNAL_USERS]} path={Routes.ADD_EXTERNAL_USER}
                              exact={true}>
              <AddExternalUserPage/>
            </RequireRoleRoute>
            <RequireRoleRoute requiredRoles={[ROLE_ADMIN, ROLE_EXTERNAL_UNITS]} path={Routes.ADD_EXTERNAL_UNIT}
                              exact={true}>
              <AddExternalUnitPage/>
            </RequireRoleRoute>
            <Route component={NotFound}/>
          </Switch>
      </div>
    </ErrorBoundary>
  );
}