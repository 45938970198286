/**
 * REST API calls
 *
 * Created by: Bruno Paulino
 * */

import axios from 'axios';
import * as reportsAPI from './reports';
import * as authAPI from './auth';
import * as copsAPI from './cops';
import * as resetPasswordAPI from './resetPassword';
import * as reportPeopleAPI from './reportPeople';
import * as appsAPI from './apps';
import * as permissionsAPI from './permissions';
import * as AdminAPI from './admin';

export const API = {
  ...reportsAPI,
  ...authAPI,
  ...copsAPI,
  ...resetPasswordAPI,
  ...reportPeopleAPI,
  ...appsAPI,
  ...permissionsAPI,
  ...AdminAPI,
  getUnits(page) {
    return axios.get('/api/units', { params: {page} });
  },
  getRootUnits() {
    return axios.get('/api/units/root')
  },
  getUnit(unitId) {
    return axios.get(`/api/units/${unitId}`)
  },
  getSubunits(unitId) {
    return axios.get(`/api/units/${unitId}/subunits`)
  },
  getUsersFromUnit(unitId, includeSubunits) {
    return axios.get(`/api/units/${unitId}/users`, {
      params: {
        includeSubunits
      }
    });
  },
  searchUnits(query = "") {
    return axios.get(`/api/units/search`, {params: {query}})
  }
};