import axios from 'axios';

export const getCops = (search, extendedSearchResult = false) => {
    return axios.get('/api/cops', {
        params: {
            q: search,
            extended: extendedSearchResult
        }
    });
};

export const updateCop = (registration, email, subunitId, phone) => {
    return axios.put(`/api/cops/${registration}`, {email, subunitId, phone});
};

export const getCopByRegistration = (registration) => {
  return axios.get(`/api/cops/${registration}`);
}

export const getCopsWithBdayOfDay = (dateString, unitId, includeSubunits = false) => {
  return axios.get(`/api/cops/bday/day/${dateString}/${unitId}`, {
    params: {
      includeSubunits
    }
  })
}

export const getCopsWithBdayOfMonth = (dateString, unitId, includeSubunits = false) => {
  return axios.get(`/api/cops/bday/month/${dateString}/${unitId}`, {
    params: {
      includeSubunits
    }
  })
}