import React, {useReducer, useEffect, useState} from 'react';
import {API} from "../../../network/api";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Loader from "../../common/Loader";
import {
  Button,
} from "@material-ui/core";
import Routes from "../../../constants/Routes";
import PaddingPaper from "../../common/PaddingPaper";

const FETCH = 'FETCH';
const FETCH_ERROR = 'FETCH_ERROR';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_SUBUNITS_SUCCESS = 'FETCH_SUBUNITS_SUCCESS'
const FETCH_SUBUNITS_ERROR = 'FETCH_SUBUNITS_ERROR'

const ROOT_UNIT_ID = 'root-unit-id'
const ROOT_EXTERNAL_UNIT_ID = 'root-external-unit-id'

const initialState = {
  status: FETCH,
  units: [],
  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH: {
      return {
        ...state,
        status: FETCH,
        error: null,
      };
    }

    case FETCH_ERROR: {
      return {
        ...state,
        status: FETCH_ERROR,
        error: 'Erro ao carregar unidades',
      };
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        status: FETCH_SUCCESS,
        error: null,
        units: [
          {
            id: ROOT_UNIT_ID,
            name: 'Unidades Militares',
            subunits: [
              ...action.units.filter(u => !u.isExternal)
            ]
          },
          {
            id: ROOT_EXTERNAL_UNIT_ID,
            name: 'Unidades Externas',
            subunits: [
              ...action.units.filter(u => u.isExternal)
            ]
          }
        ]
      };
    }

    case FETCH_SUBUNITS_SUCCESS: {

      function updateSubunits(units, unitId, newSubunits) {
        return units.map(u => {
          if (u.id === unitId) {
            return {...u, subunits: newSubunits}
          } else if (u.subunits) {
            return {...u, subunits: updateSubunits(u.subunits, unitId, newSubunits)}
          } else {
            return u
          }
        }).flat()
      }

      const {unitId, subunits} = action;
      const updatedUnits = updateSubunits(state.units, unitId, subunits)
      return {
        ...state,
        error: null,
        units: updatedUnits
      }
    }
    default:
      return state;
  }
};

export default function MilitaryUnits() {
  // Root Unit is always expanded
  const [expanded, setExpanded] = useState([ROOT_UNIT_ID, ROOT_EXTERNAL_UNIT_ID]);
  const [fetchUnitId, setFetchUnitId] = useState(null)
  const [currentUnitId, setCurrentUnitId] = useState(null)
  const [{units}, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function fetchRootUnits() {
      try {
        dispatch({type: FETCH})
        const response = await API.getRootUnits()
        const {rootUnits} = response.data
        console.log({rootUnits})
        dispatch({type: FETCH_SUCCESS, units: rootUnits})
      } catch (e) {
        dispatch({type: FETCH_ERROR, error: 'Não foi possível carregar unidades raiz'})
        console.error("Could not fetch units", e)
      }
    }

    fetchRootUnits()
  }, [])

  const findSubunits = (units, unitId) => {
    return units.find(u => {
      if (u.id === unitId) {
        return u.subunits && u.subunits.length
      } else if (u.subunits) {
        return findSubunits(u.subunits, unitId)
      } else {
        return false
      }
    })
  };

  const onSelectUnit = async (event, unitId) => {
    const intUnitId = parseInt(unitId)

    // Root unit doesn't trigger requests
    if (unitId === ROOT_UNIT_ID || unitId === ROOT_EXTERNAL_UNIT_ID) return

    setCurrentUnitId(unitId)

    // There is an unit being fetched. do not trigger more requests
    if (fetchUnitId) return

    // Unit is already expanded. Collapse it
    if (expanded.includes(unitId)) {
      setExpanded(expandedUnits => expandedUnits.filter(e => e !== unitId))
      return
    }

    const existingSubunits = findSubunits(units, intUnitId)
    // Subunits already cached from prev. requests
    // just expand it again
    if (existingSubunits) {
      setExpanded(e => [...e, unitId])
      return
    }

    try {
      setFetchUnitId(intUnitId)
      const response = await API.getSubunits(intUnitId)
      setExpanded(e => [...e, unitId])
      dispatch({type: FETCH_SUBUNITS_SUCCESS, unitId: intUnitId, subunits: response.data.subunits})
    } catch (e) {
      console.error('erro ao verificar unidades', e)
      dispatch({type: FETCH_SUBUNITS_ERROR, error: 'Não foi possível carregar subunidades'})
    } finally {
      setFetchUnitId(null)
    }
  }

  const renderTree = (unit, parentUnitName = '') => {
    const style = {
      opacity: fetchUnitId === unit.id ? 0.6 : 1
    }
    const label = (
      <div style={{fontWeight: currentUnitId === unit.id.toString() ? 'bold' : 'normal'}}>
        {unit.name.replaceAll(`${parentUnitName}|`, '')}
        {fetchUnitId === unit.id ? <Loader position="relative" size={12}/> : null}
      </div>
    )
    return (
      <TreeItem key={unit.id} nodeId={`${unit.id}`} label={label} style={style}>
        {Array.isArray(unit.subunits) ? unit.subunits.map((node) => renderTree(node, unit.name)) : null}
      </TreeItem>
    )
  };

  const showUsers = () => {
    if (currentUnitId) {
      const url = Routes.MILITARY_UNIT_DETAIL_PATH(currentUnitId)
      const newWindow = window.open(url, "_blank");
      newWindow.focus();
    }
  }

  return (
    <PaddingPaper>
      <TreeView
        style={{width: '100%', height: '100%', maxHeight: 500, overflowY: 'scroll', padding: 8}}
        expanded={expanded}
        onNodeSelect={onSelectUnit}
        defaultCollapseIcon={<ExpandMoreIcon/>}
        defaultEndIcon={<ChevronRightIcon/>}
        defaultExpandIcon={<ChevronRightIcon/>}
      >
        {units.map(renderTree)}
      </TreeView>
      <Button variant="contained"
              color="primary"
              style={{marginTop: 8}}
              disabled={currentUnitId === null}
              onClick={showUsers}>
        Visualizar efetivo da unidade
      </Button>
    </PaddingPaper>
  )

}