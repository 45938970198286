import { API } from '../network/api';

export const REQUEST_RESET_EMAIL = 'REQUEST_RESET_EMAIL';
export const requestResetEmailStart = (email) => {
    return {
        type: REQUEST_RESET_EMAIL,
        email
    }
};

export const REQUEST_RESET_EMAIL_SUCCESS = 'REQUEST_RESET_EMAIL_SUCCESS';
const receiveResetEmail = (response) => {
    return {
        type: REQUEST_RESET_EMAIL_SUCCESS,
        response
    }
};

export const REQUEST_RESET_EMAIL_FAILURE = 'REQUEST_RESET_EMAIL_FAILURE';
const requestResetEmailFailed = (error) => {
    return {
        type: REQUEST_RESET_EMAIL_FAILURE,
        error: error.message || 'Erro ao solicitar email de reset de senha.'
    }
};

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const updatePassword = (password) => {
    return {
        type: UPDATE_PASSWORD,
        password
    };
};

export const UPDATE_PASSWORD_CONFIRMATION = 'UPDATE_PASSWORD_CONFIRMATION';
export const updatePasswordConfirmation = (passwordConfirmation) => {
    return {
        type: UPDATE_PASSWORD_CONFIRMATION,
        passwordConfirmation
    };
};

export const REQUEST_VALIDATE_TOKEN = 'REQUEST_VALIDATE_TOKEN';
const requestValidateTokenStart = () => {
  return { type: REQUEST_VALIDATE_TOKEN };
};

export const REQUEST_VALIDATE_TOKEN_SUCCESS = 'REQUEST_VALIDATE_TOKEN_SUCCESS';
const receiveValidateToken = () => {
    return {
        type: REQUEST_VALIDATE_TOKEN_SUCCESS
    };
};

export const REQUEST_VALIDATE_TOKEN_FAILURE = 'REQUEST_VALIDATE_TOKEN_FAILURE';
const requestValidateTokenFailed = (error) => {
    console.log(error);
    return {
        type: REQUEST_VALIDATE_TOKEN_FAILURE,
        error: error.message || 'Ocorreu um erro. tente novamente.'
    };
};

export const requestResetEmail = (email) => (dispatch) => {
    dispatch(requestResetEmailStart(email));
    return API.postRequestResetPasswordEmail(email)
        .then(
            response => dispatch(receiveResetEmail(response.data)),
            error => dispatch(requestResetEmailFailed(error.response.data))
        );
};

export const requestValidateToken = (token) => (dispatch) => {
  dispatch(requestValidateTokenStart());
  return API.postValidateResetToken(token)
      .then(
          response => dispatch(receiveValidateToken()),
          error => dispatch(requestValidateTokenFailed(error.response.data))
      );
};

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
const requestResetPasswordStart = () => {
    return {
        type: REQUEST_RESET_PASSWORD
    };
};

export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
const requestResetPasswordSuccess = (response) => {
    return {
        type: REQUEST_RESET_PASSWORD_SUCCESS,
        redirectUrl: response.redirectUrl || null
    };
};

export const REQUEST_RESET_PASSWORD_FAILED = 'REQUEST_RESET_PASSWORD_FAILED';
const requestResetPasswordFailed = (error) => {
  return {
      type: REQUEST_RESET_PASSWORD_FAILED,
      error: error.message || 'Ocorreu um erro. tente novamente.'
  }
};

export const requestUpdatePassword = (token, password, passwordConfirmation) => (dispatch) => {
    dispatch(requestResetPasswordStart());
    return API.postResetPassword(token, password, passwordConfirmation)
        .then(response => dispatch(requestResetPasswordSuccess(response.data)),
            error => dispatch(requestResetPasswordFailed(error.response.data)))
};