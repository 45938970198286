import React, {createContext, useCallback, useContext, useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const GlobalNotificationContext = createContext(null);
GlobalNotificationContext.displayName = 'GlobalNotificationContext';


const TIME_SHOWING_NOTIFICATION = 4000;

export default function GlobalNotifications({children}) {

  const [isShowing, setIsShowing] = useState(false);
  const [info, setInfo] = useState({message: '', severity: 'success'});

  const showSuccess = useCallback((message) => {
    setInfo({message, severity: 'success'});
    setIsShowing(true);
    setTimeout(() => setIsShowing(false), TIME_SHOWING_NOTIFICATION);
  }, []);

  const showFailure = useCallback((message) => {
    setInfo({message, severity: 'error'});
    setIsShowing(true);
    setTimeout(() => setIsShowing(false), TIME_SHOWING_NOTIFICATION);
  }, []);

  return(
    <GlobalNotificationContext.Provider value={{showSuccess, showFailure}}>
      {children}
      <Snackbar open={isShowing}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                autoHideDuration={TIME_SHOWING_NOTIFICATION}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => setIsShowing(false)}>
                    <CloseIcon/>
                  </IconButton>
                ]}>
        <Alert severity={info.severity}>{info.message}</Alert>
      </Snackbar>
    </GlobalNotificationContext.Provider>
  )
}

export function useGlobalNotifications() {
  const context = useContext(GlobalNotificationContext)
  if (context === null) {
    throw new Error(
      'useAuth must be used within GlobalNotificationContext.' +
      '\n' +
      'Make sure to put <GlobalNotificationContext> on top of you component tree'
    )
  }
  return context
}