import {
    TOGGLE_SIDEBAR,
    UPDATE_TOP_LEVEL_TITLE
} from "../actions/global";

import { combineReducers } from 'redux';

const toggleSidebar = (state = false, action) => {

    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return !state;

        default:
            return state;
    }

};

const topLevelTitle = (state = 'PMPB', action) => {

    switch (action.type) {
        case UPDATE_TOP_LEVEL_TITLE:
            return action.title;

        default:
            return state;
    }
};

export const global = combineReducers({
    toggleSidebar,
    topLevelTitle
});

// Selectors
export const getTopLevelTitle = (state) => {
  return state.global.topLevelTitle;
};

export const getToggleSideBar = (state) => {
    return state.global.toggleSidebar;
};

