import React, {useEffect, useState} from "react";
import moment from 'moment-timezone';

import {useGlobalNotifications} from "../../common/GlobalNotifications";
import {updateCop} from "../../../network/cops";
import {API} from "../../../network/api";
import {
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import {Link, useParams} from "react-router-dom";
import Routes from "../../../constants/Routes";
import AutocompleteSelect from "../../common/AutocompleteSelect";
import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import useSearchMilitaryUnits from "../../../hooks/useSearchMilitaryUnits";
import userImg from '../../../images/user.jpg';
import UserActivity from "./UserActivity";
import AdminTasks from "./AdminTasks";

export default function EditCopPage() {
  const {registration} = useParams()
  const [cop, setCop] = useState(null);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedUnit, setSelectedUnit] = useState(null)
  const {showSuccess, showFailure} = useGlobalNotifications();
  const {units, clearUnits, onSearchUnit} = useSearchMilitaryUnits(() => {
    showFailure('Não foi possível buscar por unidades. Tente mais tarde.')
  })

  useEffect(() => {
    API.getCops(registration, true).then(({data}) => {
      const [cop] = data.cops;
      setCop(cop)
      if (cop.militarySubunit) {
        setSelectedUnit({
          id: cop.militarySubunit.id,
          primary: cop.militarySubunit.name
        })
      }
      setEmail(cop.email)
      setPhone(cop.phone)
    }).catch(err => {
      console.error('Could not load cop', err)
      showFailure('não foi possível carregar dados. tente novamente.')
    })
  }, [registration, showFailure])

  const onSubmit = (e) => {
    e.preventDefault();
    const subunitId = selectedUnit ? selectedUnit.id : null
    const {registration} = cop
    updateCop(registration, email, subunitId, phone).then(_ => {
      showSuccess(`Usuário ${registration} atualizado com sucesso`);
    }, error => {
      console.log('could not update cop email', error.response.data);
      showFailure(error.response?.data?.error || `Erro ao atualizar usuario ${registration}`)
    });
  };

  const onSelectedUnit = (unit) => {
    clearUnits()
    setSelectedUnit(unit)
  }

  if (!cop) {
    return <Loader/>
  }

  const isExternal = cop.militaryUnit && cop.militaryUnit.isExternal

  console.log('cop', cop)

  return (
    <div style={{maxWidth: 600, margin: 'auto'}}>
      <form onSubmit={onSubmit}>
        <Paper style={{padding: 16}}>
          <div>
            <img style={{width: 100}} src={cop.imageUrl || userImg} alt={cop.name}/>
          </div>
          <Typography color="textSecondary" gutterBottom>
            Usuário
          </Typography>
          <Typography color="textPrimary" gutterBottom>
            {`${cop.rank} ${cop.fullName}`}
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Matrícula
          </Typography>
          <Typography color="textPrimary" gutterBottom>
            {cop.registration}
          </Typography>

          {!isExternal && (
            <>
              <Typography color="textSecondary" gutterBottom>
                Data de Nascimento
              </Typography>
              <Typography color="textPrimary" gutterBottom>

                {moment(cop.birthdate).utc().format('DD/MM/YYYY')} ({cop.age} anos)
              </Typography>
            </>
          )}

          <Typography color="textSecondary" gutterBottom>
            Situação
          </Typography>
          <Typography color="textPrimary" gutterBottom>

            { cop.situation }
          </Typography>

          <Typography color="textSecondary" gutterBottom>
            Unidade de lotação
            {
              cop.militaryUnit && (
                <>
                  {
                    isExternal
                      ? ' (Unidade Externa)'
                      : ' (Baseado em dados do EM8)'
                  }
                </>
              )
            }
          </Typography>
          <Typography color="textPrimary" gutterBottom>
            {
              cop.militaryUnit
                ? (
                  <Link to={Routes.MILITARY_UNIT_DETAIL_PATH(cop.militaryUnit.id)}>
                    {cop.militaryUnit.name}
                  </Link>
                )
                : 'Não definido'
            }
          </Typography>

          <Typography color="textSecondary" gutterBottom>
            Email
          </Typography>

          <TextField type="email" name="user-email" label="email" required fullWidth value={email}
                     onChange={e => setEmail(e.target.value)}/>
          <TextField name="user-phone" label="Telefone" fullWidth value={phone}
                     onChange={e => setPhone(e.target.value)}/>

          <Typography color="textSecondary" gutterBottom style={{marginTop: 16}}>
            Unidade de lotação definida pelo EM2
          </Typography>

          <AutocompleteSelect items={units}
                              label="busque por uma unidade"
                              onItemSelected={onSelectedUnit}
                              onSearch={onSearchUnit}
                              selectedItem={selectedUnit}/>

          {selectedUnit && (
            <Typography color="textSecondary" gutterBottom style={{marginTop: 16, fontSize: 12}}>
              <Link to={Routes.MILITARY_UNIT_DETAIL_PATH(selectedUnit.id)} style={{fontSize: '1rem'}}>
                Listar {isExternal ? 'usuários' : 'militares'} lotados em {selectedUnit.primary}
              </Link>
            </Typography>
          )}
        </Paper>
        <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', padding: '16px 0'}}>
          <Button variant="contained"
                  color="primary"
                  type="submit"
                  disabled={email.length === 0}>
            Salvar
          </Button>
        </div>
      </form>

      <AdminTasks userId={cop.id} />

      <UserActivity userId={cop.id}/>
    </div>
  );
}
