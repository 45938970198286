import {API} from "../network/api";
import {useCallback, useRef, useState} from "react";

export default function useSearchMilitaryUnits(onError) {

  const [isSearching, setIsSearching] = useState(false)
  const [units, setUnits] = useState([])
  const onErrorCallback = useRef()
  onErrorCallback.current = onError

  const clearUnits = useCallback(() => setUnits([]), [])

  const onSearchUnit = useCallback(async (query) => {
    if (isSearching) return
    setIsSearching(true)
    try {
      const result = await API.searchUnits(query)
      const mappedUnits = result.data.units.map(u => ({id: u.id, primary: u.name}))
      setUnits([...mappedUnits])
    } catch (e) {
      console.error("Could not search Units", e)
      if (onErrorCallback.current) {
        onErrorCallback.current(e)
      }
    } finally {
      setIsSearching(false)
    }
  }, [isSearching])

  return {units, clearUnits, onSearchUnit}
}