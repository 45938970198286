/**
 * Auth actions
 * Manage user authentication
 * */

import {API} from '../network/api';

export const CHANGE_LOGIN_FORM = 'CHANGE_LOGIN_FORM';

export const POST_SIGN_IN_REQUEST = "POST_SIGN_IN_REQUEST";
export const POST_SIGN_IN_FAILURE = "POST_SIGN_IN_FAILURE";
export const POST_SIGN_IN_SUCCESS = "POST_SIGN_IN_SUCCESS";
export const USER_DID_SIGN_OUT = "USER_DID_SIGN_OUT";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";

export const changeLoginForm = (key, value) => {
  return {
    type: CHANGE_LOGIN_FORM,
    data: {
      [key]: value
    }
  };
};

const getProfileRequest = () => {
  return {
    type: GET_PROFILE_REQUEST
  };
};

const getProfileSuccess = ({user, roles}) => {
  return {
    type: GET_PROFILE_SUCCESS,
    user,
    roles
  };
};

const getProfileFailed = (error) => {
  return {
    type: GET_PROFILE_FAILURE,
    error
  }
};

const signInRequest = () => {
  return {
    type: POST_SIGN_IN_REQUEST
  };
};

const signInSuccess = ({user, roles, temporaryToken}) => {
  return {
    type: POST_SIGN_IN_SUCCESS,
    user,
    roles,
    temporaryToken
  };
};

const signInFailed = () => {
  return {
    type: POST_SIGN_IN_FAILURE,
    error: 'Email ou senha inválidos.'
  }
};

export const userSignOut = () => {
  return {
    type: USER_DID_SIGN_OUT
  };
};

export const postSignIn = (login, password, callbackUrl) => (dispatch) => {

  dispatch(signInRequest());
  return API.postSignIn(login, password, callbackUrl)
    .then(
      response => dispatch(signInSuccess(response.data)),
      error => dispatch(signInFailed())
    );
};

export const DELETE_SIGN_OUT_REQUEST = 'DELETE_SIGN_OUT_REQUEST';
export const DELETE_SIGN_OUT_FAILURE = 'DELETE_SIGN_OUT_FAILURE';

export const signOutRequest = () => {
  return {
    type: DELETE_SIGN_OUT_REQUEST
  };
};

export const signOutFailure = () => {
  return {
    type: DELETE_SIGN_OUT_FAILURE
  };
};

const signOutSuccess = () => {
  return userSignOut()
};

export const deleteSignOut = () => (dispatch) => {

  dispatch(signOutRequest());
  return API.deleteSignOut().then(
    response => dispatch(signOutSuccess()),
    error => dispatch(signOutFailure())
  );

};

export const getProfile = () => (dispatch) => {
  dispatch(getProfileRequest());
  return API.getUserProfile().then(
    response => dispatch(getProfileSuccess(response.data)),
    error => dispatch(getProfileFailed())
  );
};