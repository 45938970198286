import React, {useContext, useEffect, useState} from 'react';
import {TextField, Typography, Select, InputLabel, MenuItem} from "@material-ui/core";
import useForm from "../../../hooks/useForm";
import Button from "@material-ui/core/Button";
import {getExternalUnits, postCreateExternalUser} from "../../../network/externalUsers";
import {GlobalNotificationContext} from "../../common/GlobalNotifications";
import {useHistory} from "react-router-dom";
import Routes from "../../../constants/Routes";
import PaddingPaper from "../../common/PaddingPaper";

export default function AddExternalUserPage() {

  const [isLoading, set] = useState(false);
  const {showSuccess, showFailure} = useContext(GlobalNotificationContext);
  const history = useHistory();

  const [externalUnits, setExternalUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');

  const [, onSubmit, onChange] = useForm((values) => {
    set(true);
    const postValues = {...values, militaryUnitId: selectedUnit}
    console.log('postValues: ', postValues)
    postCreateExternalUser(postValues).then(response => {
      showSuccess('Usuário externo criado com sucesso.');
      set(false);
      history.push(Routes.MANAGE_COPS_SEARCH)
    }, error => {
      showFailure(error.response.data.error);
      set(false);
    });
  });

  useEffect(() => {
    set(true)
    getExternalUnits().then(response => {
      set(false)
      setExternalUnits(response.data.units);
    }, error => {
      console.error("Could not get external units", error)
      showFailure('Não foi possível carregar unidades. tente novamente.')
    });
  }, [showFailure]);

  const buttonIsDisabled = isLoading || selectedUnit === '';
  const buttonText = isLoading ? 'Salvando' : 'Salvar'

  return(
    <>
      <Typography component="h6" variant="h6">Adicionar Usuário Externo</Typography>
      <Typography>Usuários externos pertencem ao quadro do estado, por tanto precisam possuir uma matrícula válida</Typography>
      <form onSubmit={onSubmit} style={{padding: '16px 0'}}>
        <PaddingPaper>
          <TextField type="email" name="email" label="email" required fullWidth onChange={onChange}/>
          <TextField type="text" name="name" label="Nome completo" required fullWidth onChange={onChange}/>
          <TextField type="text" name="registration" label="Matrícula" required fullWidth onChange={onChange}/>
          <TextField type="text" name="rank" label="Função" required fullWidth onChange={onChange}/>
          <TextField type="text" name="cpf" label="CPF" required fullWidth onChange={onChange}/>
          <InputLabel id="military-unit-id" style={{marginTop: 16}}>Unidade Externa</InputLabel>
          {externalUnits && externalUnits.length > 0 && (
            <Select onChange={event => setSelectedUnit(event.target.value)}
                    label="Unidade Externa"
                    name="militaryUnitId"
                    id="military-unit-id"
                    fullWidth
                    value={selectedUnit}
                    required>
              {externalUnits.map(u => <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>)}
            </Select>
          )}
        </PaddingPaper>
        <Button variant="contained"
                color="primary"
                type="submit"
                disabled={buttonIsDisabled}
                style={{float: 'right', marginTop: '10px', marginBottom: '10px'}}>
          {buttonText}
        </Button>
      </form>
    </>

  );

}