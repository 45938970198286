import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {API} from "../../../network/api";

import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import CopsList from "../cops/CopsList";

export default function MilitaryUnitUsers() {

  const {id} = useParams();
  const [includeSubunits, setIncludeSubunits] = useState(false)
  const [{users, unit}, setData] = useState({users: [], unit: null});

  useEffect(() => {
    API.getUsersFromUnit(id, includeSubunits)
      .then(response => {
        setData({...response.data});
      }, error => {
        console.error("Could not load users", error.response.data);
      })
  }, [id, includeSubunits]);

  return (
    <>
      <Typography gutterBottom variant="h5" component="h2">
        {unit && `${unit.isExternal ? 'Usuários' : 'Militares'} lotados na unidade ${unit.name}`}
      </Typography>
      <div>
        <FormControlLabel control={
          <Checkbox
            checked={includeSubunits}
            onChange={() => setIncludeSubunits(v => !v)}
            inputProps={{ 'aria-label': 'Incluir militares de subunidades' }}/>
        } label="Incluir militares de subunidades"/>
      </div>
      {users && <CopsList cops={users}/>}
    </>
  );
}