import React, {
  useState,
  createContext,
  useContext, useEffect, useCallback,
} from 'react'

const GlobalContext = createContext(null);
GlobalContext.displayName = 'GlobalSettingsContext';

export default function GlobalSettingsProvider({children}) {

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)
  const [topLevelTitle, setTopLevelTitle] = useState('Apps PMPB')

  const toggleSidebar = useCallback(() => {
    setIsSidebarExpanded(value => !value)
  }, [])

  useEffect(() => {
    document.title = topLevelTitle
  }, [topLevelTitle])

  return (
    <GlobalContext.Provider value={{isSidebarExpanded, toggleSidebar, topLevelTitle, setTopLevelTitle}}>
      {children}
    </GlobalContext.Provider>
  )
}

export function useGlobalSettings() {
  const context = useContext(GlobalContext)
  if (context === null) {
    throw new Error(
      'useGlobalSettings must be used within GlobalSettingsProvider.' +
      '\n' +
      'Make sure to put <GlobalSettingsProvider> on top of you component tree'
    )
  }
  return context

}