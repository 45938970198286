import axios from 'axios';

export const postRequestResetPasswordEmail = (email) => {
    return axios.post('/api/login/reset/request', { email });
};

export const postValidateResetToken = (token) => {
    return axios.post('/api/login/reset/validate', { token });
};

export const postResetPassword = (token, password, passwordConfirmation) => {
  return axios.post('/api/login/reset/confirm', {token, password, passwordConfirmation});
};