import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/index.js';

// Activate some middlewares to enhance our store and return the newly created redux store
// - activate redux devtools on chrome
// - logger and thunk support
const middlewareList = [thunkMiddleware];

// Compose enhancers to be able to use the redux debugger
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reduxStore = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewareList))
);

export default reduxStore;