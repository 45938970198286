import axios from 'axios';

export const postCreateExternalUser = ({name, email, cpf, rank, registration, militaryUnitId}) => {
  return axios.post('/api/external/users', { name, email, cpf, rank, registration, militaryUnitId});
};

export const getExternalUnits = () => {
  return axios.get('/api/external/units');
}

export const postCreateExternalUnit = ({name}) => {
  return axios.post('/api/external/units', {name});
}