import {
    REQUEST_RESET_EMAIL,
    REQUEST_RESET_EMAIL_FAILURE,
    REQUEST_RESET_EMAIL_SUCCESS
} from "../actions/resetPassword";

import {
    REQUEST_VALIDATE_TOKEN,
    REQUEST_VALIDATE_TOKEN_SUCCESS,
    REQUEST_VALIDATE_TOKEN_FAILURE,
} from "../actions/resetPassword";

import {
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_CONFIRMATION,
    REQUEST_RESET_PASSWORD,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_FAILED
} from "../actions/resetPassword";

import { combineReducers } from 'redux';

// Reducers
const requestToken = (
    state = {
        isFetching: false,
        isDone: false,
        error: null
    },
    action
) => {

    switch (action.type) {

        case REQUEST_RESET_EMAIL:
            return {
                ...state,
                isFetching: true,
                error: null
            };

        case REQUEST_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: null,
                isDone: true
            };

        case REQUEST_RESET_EMAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        default: return state;
    }

};

export const getIsFetching = (state) => { return state.resetPassword.requestToken.isFetching; };
export const getIsDone = (state) => { return state.resetPassword.requestToken.isDone; };
export const getRequestTokenError = (state) => { return state.resetPassword.requestToken.error; };

const validateToken = (
    state = {
        isValidatingToken: false,
        isTokenValid: null,
        error: null
    },
    action
) => {

    switch (action.type) {
        case REQUEST_VALIDATE_TOKEN:
            return {
                ...state,
                isValidatingToken: true
            };
        case REQUEST_VALIDATE_TOKEN_SUCCESS:
            return {
                ...state,
                isValidatingToken: false,
                isTokenValid: true
            };
        case REQUEST_VALIDATE_TOKEN_FAILURE:
            return {
                ...state,
                isValidatingToken: false,
                isTokenValid: false,
                error: action.error
            };
        default: return state;
    }

};

const passwordReset = (
    state = {
        isResettingPassword: false,
        didResetWork: null,
        redirectUrl: null,
        password: null,
        passwordConfirmation: null,
        error: null
    },
    action
) => {

    switch (action.type) {

        case UPDATE_PASSWORD:
            return {
                ...state,
                password: action.password
            };
        case UPDATE_PASSWORD_CONFIRMATION:
            return {
                ...state,
                passwordConfirmation: action.passwordConfirmation
            };
        case REQUEST_RESET_PASSWORD:
            return {
                ...state,
                isResettingPassword: true,
                didResetWork: null,
                error: null
            };
        case REQUEST_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isResettingPassword: false,
                redirectUrl: action.redirectUrl,
                didResetWork: true
            };
        case REQUEST_RESET_PASSWORD_FAILED:
            return {
                ...state,
                isResettingPassword: false,
                didResetWork: false,
                error: action.error
            };

        default: return state;

    }

};

export const resetPassword = combineReducers(
    {
        requestToken,
        validateToken,
        passwordReset
    }
);

export const getIsValidatingToken = (state) => { return state.resetPassword.validateToken.isValidatingToken; };
export const getIsResettingPassword = (state) => { return state.resetPassword.validateToken.isResettingPassword };
export const getIsTokenValid = (state) => { return state.resetPassword.validateToken.isTokenValid };
export const getPassword = (state) => { return state.resetPassword.passwordReset.password };
