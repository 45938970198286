import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from "react-router-dom";
import {getUsersWithAppRole} from "../../../network/permissions";
import {useGlobalNotifications} from "../../common/GlobalNotifications";
import {Typography} from "@material-ui/core";
import Loader from "../../common/Loader";
import CopsList from "../cops/CopsList";

export default function UsersWithPermissionInAppListUsersPage() {

  const {appId, roleId} = useParams()
  const queryParams = new URLSearchParams(useLocation().search)
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {showFailure} = useGlobalNotifications()

  useEffect(() => {
    getUsersWithAppRole(appId, roleId).then(res => {
      const {users} = res.data;
      setUsers(users)
    }).catch(err => {
      console.error("Nao foi possivel carregar usuarios", err.response);
      const {error} = err.response.data;
      showFailure(error || 'Não foi possível carregar usuarios')
    }).finally(() => setIsLoading(false))
  }, [appId, roleId, showFailure])

  if (isLoading) return <Loader/>

  return (
    <>
      <Typography gutterBottom variant="h4" component="h1">
        Usuários com permissão:
      </Typography>
      <Typography gutterBottom variant="h5" component="h2">
        {queryParams.get('name')}
      </Typography>
      {users && <CopsList cops={users}/>}
    </>

  )


}