import React from 'react';
import {Typography, withStyles} from "@material-ui/core";
import {API} from "../../network/api";
import Grid from "@material-ui/core/Grid/index";
import ExternalAppCard from "../admin/externalApps/ExternalAppCard";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3 * 2))]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },
  cardGrid: {
    padding: `${theme.spacing(2)}px 0`,
  },

  emptyMessage: {
    textAlign: 'center',
    width: '100%',
    marginTop: 10,
    padding: 8,
  }
});

class AppsOverviewContainer extends React.Component {

  state = {
    isLoading: false,
    temporaryToken: null,
    externalApps: []
  };

  componentDidMount() {
    API.getUserApps().then(response => {
      const {externalApps, temporaryToken} = response.data;
      this.setState(state => ({...state, externalApps, temporaryToken}));
    }).catch(error => {
      console.error('error from apps', error.response);
    })
  }

  onRenewTempToken = () => {
    API.putRenewTemporaryToken().then(response => {
      const {temporaryToken} = response.data;
      this.setState(state => ({...state, temporaryToken}));
    }).catch(error => {
      console.error("Could not renew temporary token", error.response);
    })
  }

  render() {
    const {classes} = this.props;
    const {externalApps, temporaryToken} = this.state;
    return (
      <div className={`${classes.layout} ${classes.cardGrid}`}>
        <Typography component="h2" variant="h5">
          Meus Apps
        </Typography>
        <Grid container spacing={5} style={{margin: 0}}>
          {externalApps.map(app => <ExternalAppCard key={app.id} app={app} tempToken={temporaryToken} onRenewTempToken={this.onRenewTempToken}/>)}
        </Grid>
        {externalApps.length === 0 && (
          <Typography variant="h6" component="h6" className={classes.emptyMessage}>
            Você não possui nenhum app cadastrado no momento<br/>
            Entre em contato com nosso suporte.
          </Typography>
        )}
      </div>
    );
  }

}

AppsOverviewContainer = withStyles(styles)(AppsOverviewContainer);
export default AppsOverviewContainer;