const loadObject = (key) => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return undefined
        } else {
            return JSON.parse(serializedState);
        }
    } catch (error) {
        console.log("Couldn't load data from local storage:", error.message);
        return undefined;
    }
};


const saveObject = (key, object) => {
    try {
        const serializedObj = JSON.stringify(object);
        localStorage.setItem(key, serializedObj);
    } catch (error) {
        console.log("Couldn't persist data into local storage:", error.message);
    }
};

const cleanUp = () => {
  localStorage.clear();
};

const LocalStorageHelper = {
    loadObject,
    saveObject,
    cleanUp,
};

export default LocalStorageHelper;