import React from 'react';
import {API} from "../../../network/api";
import Loader from "../../common/Loader";
import ExternalAppForm from "./ExternalAppForm";
import Routes from "../../../constants/Routes";
import {withRouter} from "react-router-dom";
import {Typography} from "@material-ui/core";

class EditExternalApp extends React.Component {

  state = {
    isLoading: true,
    app: null,
  };

  componentDidMount() {
    const {id} = this.props.match.params;
    API.getApp(id).then(response => {
      const {app} = response.data;
      this.setState(() => ({app, isLoading: false}));
    }).catch(error => {
      this.setState(() => ({isLoading: false}));
      console.error(`Could not load app with ID ${id}`, error);
    });
  }

  render() {
    const {app, isLoading} = this.state;
    if (isLoading) {
      return <Loader/>
    }

    const onSave = (values, actions) => {
      const {id} = app;
      const {history} = this.props;
      const {name, description, baseUrl, internalUrl, authPath} = values;

      API.putUpdateApp(id, name, description, baseUrl, internalUrl, authPath).then(response => {
        history.push(Routes.MANAGE_APPS);
      }).catch(error => {
        actions.setSubmitting(false);
        console.error("Could not update app", error.response);
        const {data} = error.response;
        if (data) {
          const {error} = data;
          actions.setErrors({...error});
        }
      });
    };

    return (app &&
      <>
        <Typography component="h2" variant="h5">Editar Aplicação</Typography>
        <ExternalAppForm app={app} onSave={onSave}/>
      </>
    );

  }

}

EditExternalApp = withRouter(EditExternalApp);
export default EditExternalApp;