import { combineReducers } from 'redux';

// Import our custom reducers
import { global } from './global';
import { copSearch } from './copSearch';
import { resetPassword } from './resetPassword';
import LocalStorageHelper from '../util/LocalStorageHelper';

import { USER_DID_SIGN_OUT } from "../actions/auth";

// combine all reducers to represent the store state
const appReducer = combineReducers(
  {
    global,
    copSearch,
    resetPassword,
  }
);

const rootReducer = (state, action) => {
    // Reset app state on signout: https://stackoverflow.com/a/35641992/2301092
    if (action.type === USER_DID_SIGN_OUT) {
        LocalStorageHelper.cleanUp();
        state = undefined; // note: We are not mutating. just reassigning.
    }
    return appReducer(state, action);

};

export default rootReducer;