import {Typography} from "@material-ui/core";
import {useHistory} from 'react-router-dom';

import CopSearchForm from "../permissions/CopSearchForm";
import Routes from "../../../constants/Routes";

export const EditCopSearchPage = () => {

  const history = useHistory()

  const onCopSelected = (cop) => {
    const route = Routes.MANAGE_COPS_EDIT_PATH(cop.registration)
    history.push(route)
  }

  return (
    <div>
      <Typography component="h6" variant="h6">Atualizar Usuário</Typography>
      <CopSearchForm onSelectCop={onCopSelected} extendedSearchResult={true}/>
    </div>
  );

};