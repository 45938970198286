import React, {useContext, useState} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import Loader from '../common/Loader';
import {postRequestResetPasswordEmail} from "../../network/resetPassword";
import {GlobalNotificationContext} from "../common/GlobalNotifications";
import Routes from "../../constants/Routes";

import {Button, Link, FormControl, Input, InputLabel, Typography} from "@material-ui/core";
import AuthFormWrapper from "./AuthFormWrapper";

export default function RequestResetPassword() {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const {showSuccess, showFailure} = useContext(GlobalNotificationContext);
  const [email, setEmail] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    setIsFetching(true);
    postRequestResetPasswordEmail(email).then(response => {
      setIsFetching(false);
      showSuccess('Um email foi enviado. Verifique sua caixa de email.');
      history.push(Routes.LOGIN);
    }, _ => {
      setIsFetching(false);
      showFailure('Não foi possível enviar o email. Verifique se inseriu seu email corretamente.');
    });
  };

  const submitButton = isFetching
    ? <Loader/>
    : (
      <Button style={{marginTop: 8}}
              fullWidth
              variant="contained"
              color="primary"
              type="submit">
        Resetar Senha
      </Button>
    );

  return (
    <AuthFormWrapper>
      <Typography component="h3" variant="h6">
        Recuperar Senha
      </Typography>
      <form style={{width: '100%'}} onSubmit={onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input name="email" id="email" type="email"
                 placeholder="Você receberá um email com instruções"
                 onChange={e => setEmail(e.target.value)}
                 autoComplete="email" autoFocus/>
        </FormControl>
        {submitButton}
      </form>
      <Link component={RouterLink}
            to="/app/login"
            variant="body1" style={{display: 'block', marginTop: '10px'}}>
        Login
      </Link>
    </AuthFormWrapper>
  );

};
