/**
 * Global actions
 * Manage UI Animations
 * */

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const toggleSidebar = () => {
    return { type: TOGGLE_SIDEBAR }
};

export const UPDATE_TOP_LEVEL_TITLE = 'UPDATE_TOP_LEVEL_TITLE';
export const updateTopLevelTitle = (title) => {
    return {
        type: UPDATE_TOP_LEVEL_TITLE,
        title
    }
};