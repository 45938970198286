import {useGlobalNotifications} from "../../common/GlobalNotifications";
import React, {useState} from "react";
import {API} from "../../../network/api";
import {Paper, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default function AdminTasks({ userId }) {

  const {showSuccess, showFailure} = useGlobalNotifications();
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const [isResettingSessions, setIsResettingSessions] = useState(false)
  const [isRemovingPermissions, setIsRemovingPermissions] = useState(false)

  const sendResetPasswordEmail = () => {
    setIsSendingEmail(true)
    API.postAdminResetPwEmail(userId).then(() => {
      showSuccess('Email enviado com sucesso.')
    }).catch(() => {
      showFailure('Não foi possível enviar o email.')
    }).finally(() => setIsSendingEmail(false))
  }

  const invalidateSessions = () => {
    setIsResettingSessions(true)
    API.postAdminInvalidateSessions(userId).then(() => {
      showSuccess('Sessões invalidadas com sucesso.')
    }).catch(() => {
      showFailure('Erro ao invalidar sessões do usuário')
    }).finally(() => setIsResettingSessions(false))
  }

  const removePermissions = () => {
    setIsRemovingPermissions(true)
    API.deleteAdminRemoveAllPermissions(userId).then(() => {
      showSuccess('Permissões removidas com sucesso.')
    }).catch(() => {
      showFailure('Erro ao remover permissões. Tente novamente')
    }).finally(() => setIsRemovingPermissions(false))
  }



  return (
    <>
      <Typography component="h2" variant="h5" gutterBottom>
        Ações Administrativas
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        Execute ações de administração para esse usuário.
      </Typography>
      <Paper style={{padding: 16}}>
        <div style={{margin: '8px 0'}}>
          <Typography color="textPrimary" gutterBottom>
            Enviar email de reset sua senha.
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            O usuário receberá um email o qual conterá um link único para resetar a sua senha pessoal.
          </Typography>
          <Button variant="contained"
                  color="primary"
                  onClick={sendResetPasswordEmail} disabled={isSendingEmail}>
            ✉️ Enviar email
          </Button>
        </div>

        <div style={{margin: '16px 0'}}>
          <Typography color="textPrimary" gutterBottom>
            Remover todas as sessões do usuário.
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Atenção: Isso deslogará o usuário de todos os apps utilizando o Auth.
          </Typography>
          <Button variant="contained"
                  color="primary"
                  disabled={isResettingSessions}
                  onClick={invalidateSessions}>
            ⚠️ Deslogar usuário
          </Button>
        </div>

        <div style={{margin: '16px 0'}}>
          <Typography color="textPrimary" gutterBottom>
            Remover todas as permissões do usuário.
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Atenção: Isso removerá todos os acessos e permissões desse usuário em todos os nossos apps.
          </Typography>
          <Button variant="contained"
                  color="primary"
                  disabled={isRemovingPermissions}
                  onClick={removePermissions}>
            ⚠️ Remover Todas Permissões
          </Button>
        </div>
      </Paper>
    </>
  )

}