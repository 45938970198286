import {PropTypes} from "prop-types"
import React, {useContext, useEffect, useReducer, useState} from "react"
import {DatePicker} from "@material-ui/pickers"
import {API} from "../../../network/api"
import moment from 'moment-timezone'
import CopsList from "./CopsList"
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import AutocompleteSelect from "../../common/AutocompleteSelect";
import {GlobalNotificationContext} from "../../common/GlobalNotifications";
import useSearchMilitaryUnits from "../../../hooks/useSearchMilitaryUnits";
import {useHistory, useLocation} from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import Loader from "../../common/Loader";

const reducer = (state, action) => {
  switch (action.type) {
    case 'queryChanged': {
      return {
        ...state,
        selectedDate: action.selectedDate,
        selectedUnit: action.selectedUnit,
        includeSubunits: action.includeSubunits
      }
    }

    case 'includeSubunitsChanged': {
      return {
        ...state,
        includeSubunits: action.includeSubunits
      }
    }

    case 'dateChanged': {
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    }

    case 'unitChanged': {
      return {
        ...state,
        selectedUnit: action.selectedUnit
      }
    }

    case 'copsChanged': {
      return {
        ...state,
        cops: action.cops
      }
    }

    default:
      return state

  }
}


export default function BirthdayListPage({bdayQueryType}) {

  const [{selectedDate, selectedUnit, includeSubunits, cops}, dispatch] = useReducer(reducer, {
    selectedDate: moment(),
    includeSubunits: false,
    selectedUnit: null,
    cops: []
  })
  const [isLoading, setIsLoading] = useState(false)
  const {showFailure} = useContext(GlobalNotificationContext);
  const {units, onSearchUnit} = useSearchMilitaryUnits(() => {
    showFailure('não foi possível carregar unidades. Tente novamente em instantes.')
  })
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  // TODO: Verificar um hook "usePrevious" para
  // evitar um loop infinito de chamadas aqui
  useEffect(() => {
    const date = query.get('date')
    const unitId = query.get('unitId')
    const includeSubunits = query.get('includeSubunits') === 'true'
    if (date && unitId) {
      setIsLoading(true)
      API.getUnit(unitId).then(({data}) => {
        const {unit} = data;
        dispatch({
          type: 'queryChanged',
          selectedUnit: {
            ...unit,
            primary: unit.name
          },
          selectedDate: moment(date),
          includeSubunits
        })
      }).catch(err => {
        console.error("could not load unit: ", err)
        showFailure('Não foi possível carregar unidade. tente novamente.')
      }).finally(() => setIsLoading(false))
    }
  }, [query, showFailure])

  useEffect(() => {
    if (selectedDate && selectedUnit) {
      setIsLoading(true)
      const requestFn = bdayQueryType === 'day' ? API.getCopsWithBdayOfDay : API.getCopsWithBdayOfMonth
      const date = selectedDate.format('yyyy-MM-DD')
      requestFn(date, selectedUnit.id, includeSubunits).then(({data}) => {
        const {cops} = data
        dispatch({
          type: 'copsChanged',
          cops
        })
        // Push current search params onto URL for sharing/navigation
        const params = {date, unitId: selectedUnit.id, includeSubunits}
        history.push({
          pathname: location.pathname,
          search: `?${new URLSearchParams(params)}`
        })
      }).catch(err => {
        showFailure('Não foi possível carregar a lista. Tente novamente.')
        console.error("Could not get bday cops", err)
      }).finally(() => setIsLoading(false))
    }
  }, [selectedDate, selectedUnit, includeSubunits, bdayQueryType, history, location.pathname, showFailure])

  const onIncludeSubunitsChange = (event) => {
    dispatch({
      type: 'includeSubunitsChanged',
      includeSubunits: event.target.checked
    })
  };

  const _renderPicker = () => {
    switch (bdayQueryType) {
      case 'day': {
        return (
          <>
            <h1>Aniversariantes do dia</h1>
            <DatePicker
              label="Selecione o mês e o dia"
              value={selectedDate}
              onChange={(newDate) => dispatch(({type: 'dateChanged', selectedDate: newDate}))}
            />
          </>
        )
      }
      case 'month': {
        return (
          <>
            <h1>Aniversariantes do mês</h1>
            <DatePicker
              views={["month", "year"]}
              label="Selecione mês"
              value={selectedDate}
              onChange={(newDate) => dispatch(({type: 'dateChanged', selectedDate: newDate}))}
            />
          </>
        )
      }
      default:
        throw new Error("invalid case " + bdayQueryType)
    }
  }

  return (
    <>
      {_renderPicker()}
      <Typography color="textSecondary" gutterBottom style={{marginTop: 16}}>
        Selecione uma unidade militar
      </Typography>

      <AutocompleteSelect items={units}
                          label="busque por uma unidade"
                          onItemSelected={(u) => dispatch({type: 'unitChanged', selectedUnit: u})}
                          onSearch={onSearchUnit}
                          selectedItem={selectedUnit}/>

      <div>
        <FormControlLabel control={
          <Checkbox
            checked={includeSubunits}
            onChange={onIncludeSubunitsChange}
            inputProps={{'aria-label': 'Incluir militares de subunidades'}}/>
        } label="Incluir militares de subunidades"/>
      </div>
      {isLoading && <Loader/>}
      {cops && <CopsList cops={cops}/>}
    </>
  )
}

BirthdayListPage.propTypes = {
  bdayQueryType: PropTypes.oneOf(['day', 'month']).isRequired
}