import React, {useEffect, useMemo} from 'react'
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';

import { useAuth } from './AuthProvider'
import Routes from "../../constants/Routes";
import {intersect} from "../../util/helpers";
import {useGlobalNotifications} from "../common/GlobalNotifications";
import ErrorBoundary from "../common/ErrorBoundary";

/** Only render route component if user has required roles */
export function RequireRoleRoute({ children, requiredRoles, ...props }) {
  const { roles } = useAuth()
  const {showFailure} = useGlobalNotifications()
  const history = useHistory()
  const location = useLocation()
  const canRender = useMemo(() => {
    const roleIds = roles.map(r => r.id)
    return requiredRoles.length === 0 || intersect(requiredRoles, roleIds).length > 0
  }, [roles, requiredRoles]);

  useEffect(() => {
    if (!canRender) {
      showFailure('Você não tem permissão para acessar essa rota.')
      history.replace(Routes.DASHBOARD)
    }
  }, [canRender, showFailure, history])

  const _renderRedirect = () => <Redirect to={{pathname: Routes.DASHBOARD, state: { from: location }}}/>;

  if (props.component) {
    return canRender ? <Route {...props} /> : _renderRedirect();
  } else {
    return (
      <Route
        {...props}
        render={({ location }) =>
          canRender
            ? (
              <ErrorBoundary>
                {children}
              </ErrorBoundary>
            )
            : _renderRedirect()
        }
      />
    )
  }
}

RequireRoleRoute.propTypes = {
  requiredRoles: PropTypes.arrayOf(PropTypes.string)
}