import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {ExitToApp, Lock} from '@material-ui/icons';
import Routes from "../../constants/Routes";
import userImg from '../../images/user.jpg';
import {useGlobalSettings} from "./GlobalSettingsProvider";
import {useAuth} from "../auth/AuthProvider";
import {useHistory} from "react-router";
import {menuRoutes} from "../../constants/Roles";
import {intersect} from "../../util/helpers";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  userContainer: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  userRank: {
    marginTop: 8,
    display: 'block',
    fontSize: 10,
  },
  userName: {
    margin: 0
  },
  userImage: {
    display: 'block',
    width: 90,
    height: 90,
    borderRadius: '50%',
    objectFit: 'contain',
  }
});

export default function Sidebar() {
  const {isSidebarExpanded, toggleSidebar} = useGlobalSettings();
  const {user, roles,  onLogout} = useAuth()
  const classes = useStyles();
  const history = useHistory()

  const routes = useMemo(() => {
    const roleIds = roles.map(r => r.id)
    return menuRoutes.filter(r => {
      return r.requiredRoles.length === 0 || intersect(r.requiredRoles, roleIds).length > 0;
    })
  }, [roles])

  return (
    <Drawer open={isSidebarExpanded} onClose={toggleSidebar} anchor="left">
      <div tabIndex={0} role="button" onClick={toggleSidebar} onKeyDown={toggleSidebar}>
        <div className={classes.userContainer}>
          <img className={classes.userImage} src={user.imageUrl || userImg} alt={user.name}/>
          <span className={classes.userRank}>{user.rank} {user.registration}</span>
          <h3 className={classes.userName}>{user.name}</h3>
        </div>
        <Divider/>
        <div className={classes.list}>
          <List>
            {routes.map(({route, name, IconComponent}) => (
              <ListItem button onClick={() => history.push(route)} key={route}>
                <ListItemIcon>
                  <IconComponent/>
                </ListItemIcon>
                <ListItemText primary={name}/>
              </ListItem>
            ))}
          </List>
          <Divider/>
          <List>
            <ListItem button onClick={() => history.push(Routes.RESET_PASSWORD_REQUEST)}>
              <ListItemIcon>
                <Lock/>
              </ListItemIcon>
              <ListItemText primary="Resetar Senha"/>
            </ListItem>
            <ListItem button onClick={onLogout}>
              <ListItemIcon>
                <ExitToApp/>
              </ListItemIcon>
              <ListItemText primary="Sair"/>
            </ListItem>
          </List>
        </div>
      </div>
    </Drawer>
  );

}