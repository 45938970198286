// a reducer takes in 2 things:
// 1. copy of current state
// 2. the action(what happened)

import {
    FETCH_COPS_REQUEST,
    FETCH_COPS_SUCCESS,
    FETCH_COPS_FAILURE
} from "../actions/copSearch";

// Reducers
export const copSearch = (
    state = {
        isFetching: false,
        error: null,
        items: []
    },
    action
) => {

    switch (action.type) {
        case FETCH_COPS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_COPS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                items: action.response
            };
        case FETCH_COPS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default: return state;
    }

};

// Selectors
export const getCops = (state) => {
    return state.copSearch.items;
};

export const getIsFetching = (state) => {
    return state.copSearch.isFetching;
};

export const getErrorMessage = (state) => {
    return state.copSearch.error;
};

export const getCopById = (state, id) => {
  return state.copSearch.items.find((cop) => cop.id === id)
};