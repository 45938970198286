import { API } from '../network/api';

export const FETCH_COPS_REQUEST = 'FETCH_COPS_REQUEST';
export const FETCH_COPS_SUCCESS = 'FETCH_COPS_SUCCESS';
export const FETCH_COPS_FAILURE = 'FETCH_COPS_FAILURE';

const requestCops = () => {
    return {
        type: FETCH_COPS_REQUEST
    }
};

const receiveCops = (response) => {
    return {
        type: FETCH_COPS_SUCCESS,
        response
    }
};

const requestCopsFailed = (error) => {
    return {
        type: FETCH_COPS_FAILURE,
        error: error.message || 'Erro ao carregar militares. Tente novamente.'
    }
};

// Async action creator
export const fetchCops = (search = '') => (dispatch) => {
    // First dispatch: The app state is updated to show loading indicator
    // or whatever you need to show before start the http request.
    dispatch(requestCops());

    // that is the function called by the thunk middleware.
    // in this case, we return a promise to wait for.
    // this is not mandatory for the thunk middleware,
    // but is convenient for us.
    return API.getCops(search)
        .then(
            response => dispatch(receiveCops(response.data.cops)),

            // Do not use catch here.
            // because that will also catch any errors in the
            // dispatch and resulting render.
            // causing an loop of 'Unexpected batch number' errors.
            // https://github.com/facebook/react/issues/6895
            error => dispatch(requestCopsFailed(error))
        );
};