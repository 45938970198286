import axios from 'axios';

export const postSignIn = (login, password, callbackUrl) => {
  const headers = {
    'x-auth-app': 'Auth'
  }
  return axios.post('/api/login', {login, password, callbackUrl}, {headers});
};

export const deleteSignOut = () => {
  return axios.delete('/api/logout');
};

export const getUserProfile = () => {
  return axios.get('/api/profile');
};

/** returns the interceptor ID for further ejecting */
export const listenToUnauthorized = (cb) => {
  return axios.interceptors.response.use(
    response => response,
    error => {
      const {status} = error.response;
      if (status === 401 && typeof cb === 'function') {
        cb()
      }
      return Promise.reject(error);
    }
  );
}

export const ejectResponseInterceptor = (interceptorId) => {
  axios.interceptors.response.eject(interceptorId)
}