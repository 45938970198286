import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {getCopByRegistration} from "../../../network/cops";
import {getAppsByUserId} from "../../../network/apps";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Divider, Typography} from "@material-ui/core";
import PermissionsForm from "./PermissionsForm";
import {GlobalNotificationContext} from "../../common/GlobalNotifications";
import Button from "@material-ui/core/Button";
import * as API from "../../../network/apps";

export default function UserPermissionsListPage() {

  const {registration} = useParams();
  const [cop, setCop] = useState(null);
  const [apps, setApps] = useState([]);
  const [savingAppIds, setSavingAppIds] = useState(new Set());
  // a map of the app ID and its roles. {1: [{name: 'admin', id: 1}]}
  const [appRoles, setAppRoles] = useState({});

  const {showSuccess, showFailure} = useContext(GlobalNotificationContext);

  useEffect(() => {
    getCopByRegistration(registration).then(({data}) => {
      const {cop} = data;
      setCop(cop);
    }).catch(error => {
      showFailure("não foi possível carregar usuário");
    })
  }, [registration, showFailure]);

  useEffect(() => {
    if (cop) {
      getAppsByUserId(cop.id).then(({data}) => {
        const {apps} = data;
        setApps(apps);
      }).catch(error => {
        console.error("Could not load cop apps", error);
        showFailure("não foi possível carregar os apps.");
      });
    }
  }, [cop, showFailure]);

  const savePermissions = (appId, userId, roleIds) => {
    setSavingAppIds(appIds => new Set([...appIds, appId]));
    API.postAppPermissions(appId, userId, roleIds).then(() => {
      setSavingAppIds(appIds => new Set([...appIds].filter(id => id !== appId)));
      showSuccess('Permissões Salvas');
    }).catch(error => {
      showFailure('Erro ao salvar permissões');
      setSavingAppIds(appIds => new Set([...appIds].filter(id => id !== appId)));
      console.error("Could not save permissions", error);
    });
  };

  return(
    <Grid container>
      {cop && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Usuário
                </Typography>
                <Typography variant="h6" component="h6">
                  {`${cop.rank} ${cop.fullName}`} - {cop.registration}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
      )}

      {apps.map((app, index) => {
        return(
          <>
            <div key={app.id}>
              <PermissionsForm user={cop} app={app}
                               renderUserData={false}
                               onSelectRoles={(roles) => setAppRoles(existingRoles => ({...existingRoles, [app.id]: roles}))}/>
              <Button variant="contained"
                      color="primary"
                      style={{display: 'block', marginLeft: 'auto', marginTop: 16}}
                      disabled={savingAppIds.has(app.id) || appRoles[app.id] === undefined}
                      onClick={() => savePermissions(app.id, cop.id, appRoles[app.id].map(r => r.id))}>
                Salvar
              </Button>
            </div>
            { index < apps.length && <Divider variant="inset"/> }
          </>
        );
      })}
    </Grid>
  );

}